import React, { useState } from 'react';
import './AdminComponent.css';
import CreateAdminComponent from '../CreateAdminComponent/CreateAdminComponent';
import ChangePasswordComponent from '../ChangePasswordComponent/ChangePasswordComponent';
import DeleteAdminComponent from '../DeleteAdminComponent/DeleteAdminComponent';
import { useGetAllAdminsQuery } from '../../redux/AdminApi';
import Loading from '../Loading/Loading';

export default function AdminComponent() {

    const { data: admins, isLoading } = useGetAllAdminsQuery({ pageSize: 100, pageIndex: 1 });

    const [activeComponent, setActiveComponent] = useState('createAdminComponent');

    const components = [
        { name: 'createAdminComponent', label: 'Create Admin', component: <CreateAdminComponent /> },
        { name: 'changePasswordComponent', label: 'Change Password', component: <ChangePasswordComponent admins={admins} /> },
        { name: 'deleteAdminComponent', label: 'Delete Admin', component: <DeleteAdminComponent admins={admins} /> }
    ];

    return (
        <div className='adminComponent'>
            <div className="top flex">
                {components.map(({ name, label }) => (
                    <div
                        key={name}
                        className={`btn ${activeComponent === name ? 'active' : ''}`}
                        onClick={() => setActiveComponent(name)}
                    >
                        <p>{label}</p>
                    </div>
                ))}
            </div>
            <div className="bottom">
                {components.find(({ name }) => name === activeComponent)?.component}
            </div>
            {isLoading && <Loading />}
        </div>
    );
}