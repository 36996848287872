import React, { useContext, useEffect, useRef, useState } from 'react';
import './UpdateProductEng.css';
import search from '../../img/search.svg';
import { useUpdateProductEngMutation } from '../../redux/ProductEngApi';
import { Contexts } from '../../context/Contexts';

export default function UpdateProductEng() {

    const { EngData, errorEng, isLoadingEng, isSuccessEng } = useContext(Contexts);
    
    const [updateProductEng, result] = useUpdateProductEngMutation();

    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [maxSlice, setMaxSlice] = useState(3);
    const [clicked, setClicked] = useState(false);
    const [productId, setProductId] = useState(null);
    const [formData, setFormData] = useState({
        modelName: '',
        brandName: '',
        description: '',
        guaranteePeriod: '',
        madeIn: '',
        produced: '',
        paymentType: '',
        extraDevices: '',
        paymentContract: false,
        service: false,
    });
    const [noInfo, setNoInfo] = useState('Please enter the model name.');
    const [dataInfo, setDataInfo] = useState('');
    const [error, setError] = useState('');

    const inputRef = useRef(null);

    useEffect(() => {
        setTimeout(() => {
            inputRef.current.focus();
        }, 0);
    }, []);

    const handleSearch = (query) => {
        setSearchQuery(query);
        if (query.trim() === '') {
            setSearchResults([]);
            setClicked(false);
            setNoInfo('Please enter the model name.');
            return;
        }
        setNoInfo('No information found.');
        const results = isSuccessEng ? EngData.filter(product =>
            product.modelName.toLowerCase().includes(query.toLowerCase())
        ) : [];
        setSearchResults(results);
        setClicked(false);
        setMaxSlice(3);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setMaxSlice(prevMaxSlice => prevMaxSlice + 3);
    };

    const handleClick = (id) => {
        setClicked(true);
        setProductId(id);
        setDataInfo('');
        const product = EngData.find(product => product.id === id);
        setFormData({
            modelName: product.modelName || '',
            brandName: product.brandName || '',
            description: product.description || '',
            guaranteePeriod: product.guaranteePeriod || '',
            madeIn: product.madeIn || '',
            produced: product.produced || '',
            paymentType: product.paymentType || '',
            extraDevices: product.extraDevices || '',
            paymentContract: product.paymentContract || false,
            service: product.service || false,
        });
    };

    const handleUpdateProductEng = async (e) => {
        e.preventDefault();
        setError('');

        const requiredFields = ['modelName', 'brandName', 'description', 'guaranteePeriod', 'madeIn', 'produced', 'paymentType', 'extraDevices'];
        const emptyFields = requiredFields.filter(field => !formData[field]);

        if (emptyFields.length > 0) {
            setError('Please fill in all required fields.');
            return;
        }

        await updateProductEng({ id: productId, ...formData });
        setDataInfo('');
    };

    useEffect(() => {
        if (result.isLoading) {
            setDataInfo('Loading data.');
        } else if (result.isSuccess) {
            setFormData({
                modelName: '',
                brandName: '',
                description: '',
                guaranteePeriod: '',
                madeIn: '',
                produced: '',
                paymentType: '',
                extraDevices: '',
                paymentContract: false,
                service: false,
            });
            setDataInfo('Data has been loaded.');
            setClicked(false);
        } else if (result.isError) {
            setDataInfo('Error, please try again.');
        }
    }, [result]);

    return (
        <div className='updateProductEng'>
            <div className="top">
                <form onSubmit={handleSubmit}>
                    <input
                        ref={inputRef}
                        placeholder='Search'
                        type="text"
                        value={searchQuery}
                        onChange={(e) => handleSearch(e.target.value)}
                    />
                    <button type='submit'>
                        <img src={search} alt="search" />
                    </button>
                </form>
            </div>
            <div className='bottom flex'>
                {isSuccessEng && !clicked ? (
                    <>
                        {searchResults.slice(0, maxSlice).map(product => (
                            <div
                                onClick={() => handleClick(product.id)}
                                className='box flex'
                                key={product.id}
                            >
                                <div className="img">
                                    <img src={product.filePath} alt={product.modelName} />
                                </div>
                                <div className="text">
                                    <h3>{product.modelName.length > 80 ? `${product.modelName.slice(0, 80)}...` : product.modelName}</h3>
                                    <p>{product.brandName}</p>
                                </div>
                            </div>
                        ))}
                        {searchResults.length > maxSlice && (
                            <button onClick={handleSubmit}>More</button>
                        )}
                        {!searchResults.length && <h4 className='noInfo'>{noInfo}</h4>}
                    </>
                ) : (
                    <div className="formBox">
                        <form onSubmit={handleUpdateProductEng}>
                            {Object.entries(formData).map(([key, value]) => (
                                key === 'paymentContract' || key === 'service' ? (
                                    <div className="formGroup flex checkbox-wrapper" key={key}>
                                        <label htmlFor={key}>{key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}</label>
                                        <label className="rocker rocker-small">
                                            <input
                                                type="checkbox"
                                                id={key}
                                                checked={value}
                                                onChange={(e) => setFormData({ ...formData, [key]: e.target.checked })}
                                                className={`checkbox-${key}`}
                                            />
                                            <span className="switch-left">Yes</span>
                                            <span className="switch-right">No</span>
                                        </label>
                                    </div>
                                ) : key === 'description' ? (
                                    <div className="formGroup flex" key={key}>
                                        <label htmlFor={key}>{key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}</label>
                                        <textarea
                                            placeholder='Please write in English.'
                                            id={key}
                                            value={value}
                                            onChange={(e) => setFormData({ ...formData, [key]: e.target.value })}
                                        />
                                    </div>
                                ) : (
                                    <div className="formGroup flex" key={key}>
                                        <label htmlFor={key}>{key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}</label>
                                        <input
                                            placeholder='Please write in English.'
                                            type="text"
                                            id={key}
                                            value={value}
                                            onChange={(e) => setFormData({ ...formData, [key]: e.target.value })}
                                        />
                                    </div>
                                )
                            ))}
                            {error && <h4 className='error'>{error}</h4>}
                            <h4 className='dataInfo'>{dataInfo}</h4>
                            <button type='submit'>Update</button>
                        </form>
                    </div>
                )}
                {isLoadingEng && <h4 className='noInfo'>Loading, please wait.</h4>}
                {errorEng && <h4 className='noInfo'>Error, please try again.</h4>}
            </div>
        </div>
    );
}