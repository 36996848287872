import React, { useContext, useEffect, useState } from 'react'
import { useCreateNewEngMutation } from '../../redux/NewsEngApi'
import { useCreateNewRusMutation } from '../../redux/NewsRusApi'
import { useCreateNewUzbMutation } from '../../redux/NewsUzbApi'
import './CreateNews.css'
import AddNewsFile from '../AddNewsFile/AddNewsFile'
import { Contexts } from '../../context/Contexts'

export default function CreateNews() {

    const [titleEng, setTitleEng] = useState('')
    const [descriptionEng, setDescriptionEng] = useState('')
    const [titleRus, setTitleRus] = useState('')
    const [descriptionRus, setDescriptionRus] = useState('')
    const [titleUzb, setTitleUzb] = useState('')
    const [descriptionUzb, setDescriptionUzb] = useState('')
    const { newsFileOpen, setNewsFileOpen, setOpenLoading } = useContext(Contexts)

    const [createNewEng, resultEng] = useCreateNewEngMutation()
    const [createNewRus, resultRus] = useCreateNewRusMutation()
    const [createNewUzb, resultUzb] = useCreateNewUzbMutation()

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (titleEng === '' || descriptionEng === '' || titleRus === '' || descriptionRus === '' || titleUzb === '' || descriptionUzb === '') {
            alert('Пожалуйста, заполните все поля.')
            return;
        }

        try {
            await createNewEng({ title: titleEng, description: descriptionEng })
            await createNewRus({ title: titleRus, description: descriptionRus })
            await createNewUzb({ title: titleUzb, description: descriptionUzb })

            setTitleEng('')
            setDescriptionEng('')
            setTitleRus('')
            setDescriptionRus('')
            setTitleUzb('')
            setDescriptionUzb('')
            setNewsFileOpen(true)
        } catch (error) {
            console.error('Ошибка при отправке новостей:', error)
            alert('Произошла ошибка при отправке новостей. Пожалуйста, попробуйте еще раз.')
        }
    }

    useEffect(() => {
        if (resultEng?.isLoading || resultRus?.isLoading || resultUzb?.isLoading) {
            setOpenLoading(true)
        }
        else {
            setOpenLoading(false)
        }
    })

    return (
        <div className='createNews'>
            {!newsFileOpen &&
                <form onSubmit={handleSubmit}>
                    <input type="text" value={titleEng} onChange={(e) => setTitleEng(e.target.value)} placeholder="Заголовок на английском" />
                    <textarea value={descriptionEng} onChange={(e) => setDescriptionEng(e.target.value)} placeholder="Описание на английском" />
                    <input type="text" value={titleRus} onChange={(e) => setTitleRus(e.target.value)} placeholder="Заголовок на русском" />
                    <textarea value={descriptionRus} onChange={(e) => setDescriptionRus(e.target.value)} placeholder="Описание на русском" />
                    <input type="text" value={titleUzb} onChange={(e) => setTitleUzb(e.target.value)} placeholder="Заголовок на узбекском" />
                    <textarea value={descriptionUzb} onChange={(e) => setDescriptionUzb(e.target.value)} placeholder="Описание на узбекском" />
                    <button type="submit">Отправить</button>
                </form>
            }
            {newsFileOpen && <AddNewsFile />}
        </div>
    )
}