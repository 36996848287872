import React, { useContext, useEffect, useRef, useState } from 'react'
import './DeleteNews.css'
import { useDeleteNewEngByIdMutation } from '../../redux/NewsEngApi'
import search from '../../img/search.svg';
import { Contexts } from '../../context/Contexts';

export default function DeleteNews() {

    const { newsEngData, newsEngIsLoading, newsEngIsSuccess } = useContext(Contexts)

    const [deleteNewEngById, result] = useDeleteNewEngByIdMutation()

    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [noInfo, setNoInfo] = useState('Please enter the news title.');
    const [newsId, setNewsId] = useState('');
    const [isClicked, setIsClicked] = useState(false);

    const inputRef = useRef(null);

    useEffect(() => {
        setTimeout(() => {
            inputRef.current.focus();
        }, 0);
    }, []);

    useEffect(() => {
        if (searchQuery.trim() === '') {
            setSearchResults([]);
            setNoInfo('Please enter the news title.');
        } else {
            const results = newsEngData?.filter(news =>
                news.title.toLowerCase().includes(searchQuery.toLowerCase())
            );
            setSearchResults(results);
            setNoInfo(results.length === 0 ? 'No information found.' : '');
        }
    }, [searchQuery, newsEngData]);

    useEffect(() => {
        if (result.isSuccess) {
            setSearchResults(prevResults => prevResults.filter(news =>
                news.id !== newsId));
        }
    }, [result, newsId]);

    const handleSearch = (query) => {
        setSearchQuery(query);
    };

    const handleSubbmit = (e) => {
        e.preventDefault();
    };

    const handleClick = (id) => {
        setNewsId(id);
        setIsClicked(!isClicked);
    };

    const handleDelete = (e) => {
        e.preventDefault();
        deleteNewEngById(newsId);
        alert('News is deleted.');
        setSearchQuery('');
        setIsClicked(false);
    };

    return (
        <div className='deleteNews'>
            <div className="top">
                <form onSubmit={handleSubbmit}>
                    <input
                        ref={inputRef}
                        placeholder='Search'
                        type="text"
                        value={searchQuery}
                        onChange={(e) => handleSearch(e.target.value)}
                    />
                    <button type='submit'>
                        <img src={search} alt="" />
                    </button>
                </form>
            </div>
            <div className="bottom flex">
                {!newsEngIsLoading && newsEngIsSuccess && searchResults?.length > 0 ? searchResults?.map((news) =>
                    <div
                        className='box flex'
                        key={news.id}
                    >
                        <div className="img"
                            onClick={() => handleClick(news.id)}
                        >
                            <img src={news.attachments.filePath} alt="" />
                        </div>
                        <div className="text"
                            onClick={() => handleClick(news.id)}
                        >
                            <h3>{news.title.length > 80 ? news.title.slice(0, 80) + '...' : news.title}</h3>
                            <p>{news.description}</p>
                        </div>
                        {isClicked && newsId === news.id &&
                            <div className='deleteButton'>
                                <button
                                    onClick={handleDelete}
                                    className='deleteBtn'>
                                    Delete
                                </button>
                            </div>
                        }
                    </div>
                ) :
                    !newsEngIsLoading ? <h4 className='noInfo'>{noInfo}</h4> :
                        <h4 className='noInfo'>Please, wait!</h4>
                }
            </div>
        </div>
    )
}
