import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseQuery = fetchBaseQuery({
    baseUrl: 'http://45.138.158.239:5002',
    prepareHeaders: (headers) => {
        const token = localStorage.getItem('token');
        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
    },
});

export const fileForProductsApi = createApi({
    reducerPath: 'fileForProductsApi',
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        addAttachmentsForProducts: builder.mutation({
            query: (formData) => ({
                url: '/api/File/AddAttachmentsForProducts',
                method: 'POST',
                body: formData,
            }),
        }),
    }),
});

export const { useAddAttachmentsForProductsMutation } = fileForProductsApi;