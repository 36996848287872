import React, { useContext, useEffect } from 'react'
import './News.css'
import { Link, useNavigate } from 'react-router-dom'
import arrow from '../../img/direction-left 01.svg'
import { ReactSVG } from 'react-svg'
import svg from '../../img/maximize.svg'
import { Contexts } from '../../context/Contexts'
import { format, parseISO } from 'date-fns'

export default function News() {

    const {
        languageUz,
        languageRu,
        languageEng,
        navHeight,
        newsEngData,
        newsUzbData,
        newsRusData,
    } = useContext(Contexts)

    const navigate = useNavigate()

    const renderNewsBox = (news) => {
        if (!news) return null

        const formattedDate = news.createAt ? format(parseISO(news.createAt), 'dd.MM.yyyy') : ''

        return (
            <div className="newsBox" key={news.id}>
                <div onClick={() => navigate(`/newsInfo/${news.id}`)} className="img flex">
                    <img src={news.attachments?.filePath} alt="" />
                </div>
                <div className="text flex">
                    <h3 onClick={() => navigate(`/newsInfo/${news.id}`)} className='newsTitle'>{news.title}</h3>
                    <p className='newsDesc'>{news.description}</p>
                    <div className="newsTextBottom flex">
                        <p className='newsDate'>{formattedDate}</p>
                        <Link to={`/newsInfo/${news.id}`} className='flex'>
                            {languageUz ? 'Ko`proq ko`rish' :
                                languageEng ? 'See more' :
                                    languageRu && 'Узнать больше'
                            }
                            <img src={svg} alt="" />
                        </Link>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='news navPositionFixed' style={{ marginTop: `${navHeight}px` }}>
            <div className="container">
                <div className="pageTop">
                    <Link to={'/'}>
                        {languageUz ? 'Bosh sahifa' :
                            languageEng ? 'Home' :
                                languageRu && 'Главная страница'
                        }
                    </Link>
                    <ReactSVG src={arrow} className='mySvg' />
                    <p>
                        {languageUz ? 'Yangiliklar' :
                            languageEng ? 'News' :
                                languageRu && 'Новости'
                        }
                    </p>
                </div>
                {languageEng && newsEngData?.map(renderNewsBox)}
                {languageRu && newsRusData?.map(renderNewsBox)}
                {languageUz && newsUzbData?.map(renderNewsBox)}
            </div>
        </div>
    )
}