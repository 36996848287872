import React, { useContext, useState } from 'react';
import './ProductsPage.css';
import { Contexts } from '../../context/Contexts';
import { Link, useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import arrow from '../../img/direction-left 01.svg';

export default function ProductsPage() {
    const {
        truncateLength,
        languageUz,
        languageRu,
        languageEng,
        navHeight,
        EngData,
        RusData,
        UzbData,
        errorEng,
        errorRus,
        errorUzb,
    } = useContext(Contexts);
    const navigate = useNavigate();

    const [currentPage, setCurrentPage] = useState(1);
    const productsPerPage = 20;

    const getTotalPages = (data) => Math.ceil(data?.length / productsPerPage);
    const getCurrentProducts = (data) => {
        const startIndex = (currentPage - 1) * productsPerPage;
        const endIndex = Math.min(startIndex + productsPerPage, data?.length);
        return data?.slice(startIndex, endIndex);
    };

    const goToPage = (page) => {
        setCurrentPage(page);
    };

    const disabledPrev = () => currentPage === 1;
    const disabledNext = (totalPages) => currentPage === totalPages;

    const renderProducts = (data) => (
        getCurrentProducts(data)?.map((product) => (
            <div className="col-3 col-xs-6" key={product.id}>
                <div className="box" onClick={() => navigate(`/productInfo/${product.id}`)}>
                    <div className="carouselImg">
                        <img src={product.filePath} alt={product.modelName} />
                    </div>
                    <div className="text">
                        <p>
                            <span>{product.modelName?.length > truncateLength ? `${product.modelName?.slice(0, truncateLength)}...` : product.modelName}</span>
                        </p>
                        <p>
                            <span>{product.brandName?.length > truncateLength ? `${product.brandName?.slice(0, truncateLength)}...` : product.brandName}</span>
                        </p>
                        <p><span>{product?.produced}</span></p>
                    </div>
                </div>
            </div>
        ))
    );

    const renderSection = (language, data, error, header) => (
        <>
            <div className="pageTop">
                <Link to={'/'}>
                    {header}
                </Link>
                <ReactSVG src={arrow} className='mySvg' />
                <p>{language === 'uz' ? 'Mahsulotlar' : language === 'ru' ? 'Продукты' : 'Products'}</p>
            </div>
            <div className="row productsBox">
                {renderProducts(data)}
            </div>
        </>
    );

    const renderPagination = (data) => {
        const totalPages = getTotalPages(data);
        return (
            totalPages > 0 && (
                <div className="btnGroup flex">
                    <div className={`btn prev ${disabledPrev() ? 'disabled' : ''}`} onClick={() => goToPage(currentPage - 1)}>
                        <ReactSVG src={arrow} className='mySvg' />
                    </div>
                    {[...Array(totalPages).keys()].map((page) => {
                        if (
                            totalPages <= 7 ||
                            page === 0 ||
                            page === totalPages - 1 ||
                            (page >= currentPage - 2 && page <= currentPage + 2)
                        ) {
                            return (
                                <div
                                    className={`btn ${currentPage === page + 1 ? 'active' : ''}`}
                                    key={page}
                                    onClick={() => goToPage(page + 1)}
                                >
                                    {page + 1}
                                </div>
                            );
                        }
                        if (page === currentPage - 3 || page === currentPage + 3) {
                            return <div className="btn" key={page}>...</div>;
                        }
                        return null;
                    })}
                    <div className={`btn next ${disabledNext(totalPages) ? 'disabled' : ''}`} onClick={() => goToPage(currentPage + 1)}>
                        <ReactSVG src={arrow} className='mySvg' />
                    </div>
                </div>
            )
        );
    };

    return (
        <div className='productsPage navPositionFixed' style={{ marginTop: `${navHeight}px` }}>
            <div className="container">
                {languageUz && renderSection('uz', UzbData, errorUzb, 'Bosh sahifa')}
                {languageEng && renderSection('eng', EngData, errorEng, 'Home')}
                {languageRu && renderSection('ru', RusData, errorRus, 'Главная страница')}
                {renderPagination(languageEng ? EngData : languageRu ? RusData : UzbData)}
            </div>
        </div>
    );
}