import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseQueryWithAuth = fetchBaseQuery({
    baseUrl: "http://45.138.158.239:5002/api/",
    prepareHeaders: (headers) => {
        const token = localStorage.getItem('token');
        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
    },
    responseHandler: async (response) => {
        if (!response.ok) {
            const error = await response.text();
            throw new Error(error || response.statusText);
        }
        const contentType = response.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
            return response.json();
        } else {
            return response.text();
        }
    },
});

export const orderApi = createApi({
    reducerPath: 'orderApi',
    baseQuery: baseQueryWithAuth,
    tagTypes: ['Order'],
    endpoints: (builder) => ({
        createOrder: builder.mutation({
            query: (order) => ({
                url: '/Order/CreateOrder',
                method: 'POST',
                body: order,
            }),
            invalidatesTags: ['Order'],
        }),
        getOrderById: builder.query({
            query: (id) => `/Order/GetById?id=${id}`,
            providesTags: (result, error, id) => [{ type: 'Order', id }],
        }),
        getAllOrders: builder.query({
            query: ({ pageSize, pageIndex }) => `/Order/GetAll?pageSize=${pageSize}&pageIndex=${pageIndex}`,
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({ id }) => ({ type: 'Order', id })),
                        { type: 'Order', id: 'LIST' },
                    ]
                    : [{ type: 'Order', id: 'LIST' }],
        }),
        updateOrders: builder.mutation({
            query: (orderData) => ({
                url: '/Order/UpdateAdminData',
                method: 'PUT',
                body: orderData,
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'Order', id }],
        }),
        deleteOrder: builder.mutation({
            query: (id) => ({
                url: `/Order/DeleteAdmin?id=${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, id) => [{ type: 'Order', id }],
        }),
    }),
});

export const {
    useCreateOrderMutation,
    useGetOrderByIdQuery,
    useGetAllOrdersQuery,
    useUpdateOrdersMutation,
    useDeleteOrderMutation
} = orderApi;
