import React, { useContext, useState } from 'react';
import './AddproductFile.css'
import { useAddAttachmentsForProductsMutation } from '../../redux/FileForProducts';
import { Contexts } from '../../context/Contexts';

export default function AddProductFile() {

  const [selectedFiles, setSelectedFiles] = useState(null);
  const [addAttachmentsForProducts, { isLoading, isError }] = useAddAttachmentsForProductsMutation();

  const { setActiveProductComponent, setModelName } = useContext(Contexts)

  const handleFileChange = (e) => {
    if (e.target.files.length !== 3) {
      alert('You can select only 3 images')
    } else {
      setSelectedFiles(e.target.files);
    }
  };

  const handleUpload = async () => {
    if (!selectedFiles) return;

    const formData = new FormData();
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append('attachments', selectedFiles[i]);
    }

    try {
      await addAttachmentsForProducts(formData);
      alert('Files uploaded successfully');
      setSelectedFiles(null)
      setActiveProductComponent('addProductEng')
      setModelName('')
    } catch (error) {
      alert('Error uploading files:', error);
    }
  };

  return (
    <div className='addProductFile'>
      <input type='file' onChange={handleFileChange} multiple />
      <button onClick={handleUpload} disabled={!selectedFiles || isLoading}>
        {isLoading ? 'Uploading...' : 'Upload'}
      </button>
      {isError && <div>Error uploading files</div>}
    </div>
  );
}