import React, { useContext, useEffect, useState } from 'react';
import './AddProductRus.css';
import { Contexts } from '../../context/Contexts';
import { useCreateProductRusMutation } from '../../redux/ProductRusApi';

export default function AddProductRus() {
    const [createProductRus, createProductRusResult] = useCreateProductRusMutation();
    const { setActiveProductComponent, modelName, } = useContext(Contexts);

    const [formData, setFormData] = useState({
        brandName: '',
        description: '',
        guaranteePeriod: '',
        madeIn: '',
        produced: '',
        paymentType: '',
        extraDevices: '',
        paymentContract: false,
        service: false,
    });
    const [dataInfo, setDataInfo] = useState('');
    const [noFreePlease, setNoFreePlease] = useState('');

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const { brandName, description, guaranteePeriod, madeIn, produced, paymentType, extraDevices } = formData;

        if ([brandName, description, guaranteePeriod, madeIn, produced, paymentType, extraDevices].some((field) => field === '')) {
            setNoFreePlease("Пожалуйста, не оставляйте это поле пустым!");
            return;
        }

        try {
            const productRus = {
                modelName,
                ...formData,
            };

            await createProductRus(productRus);
            setDataInfo('Данные о продукте загружены.');
            setActiveProductComponent('addProductUzb');
        } catch (error) {
            console.error('Ошибка при добавлении товара:', error);
            setDataInfo('Ошибка при добавлении продукта. Пожалуйста, попробуйте еще раз.');
        }
    };

    useEffect(() => {
        if (createProductRusResult?.isLoading) {
            setDataInfo('Загрузка данных.');
        } else if (createProductRusResult?.isSuccess) {
            setFormData({
                brandName: '',
                description: '',
                guaranteePeriod: '',
                madeIn: '',
                produced: '',
                paymentType: '',
                extraDevices: '',
                paymentContract: false,
                service: false,
            });
            setDataInfo('Данные загрузились.');
        } else if (createProductRusResult?.isError) {
            setDataInfo('Ошибка, попробуйте еще раз.');
        }
    }, [createProductRusResult?.isError, createProductRusResult?.isLoading, createProductRusResult?.isSuccess]);

    return (
        <div className='addProductRus'>
            <form onSubmit={handleSubmit}>
                <div className="formGroup flex">
                    <label htmlFor="brandName">Название бренда</label>
                    <input
                        placeholder='Пожалуйста, пишите на русском языке.'
                        type="text"
                        id="brandName"
                        name="brandName"
                        value={formData.brandName}
                        onChange={handleChange}
                    />
                </div>
                <div className="formGroup flex">
                    <label htmlFor="produced">Произведено</label>
                    <input
                        placeholder='Пожалуйста, пишите на русском языке.'
                        type="text"
                        id="produced"
                        name="produced"
                        value={formData.produced}
                        onChange={handleChange}
                    />
                </div>
                <div className="formGroup flex">
                    <label htmlFor="paymentType">Способ оплаты</label>
                    <input
                        placeholder='Пожалуйста, пишите на русском языке.'
                        type="text"
                        id="paymentType"
                        name="paymentType"
                        value={formData.paymentType}
                        onChange={handleChange}
                    />
                </div>
                <div className="formGroup flex">
                    <label htmlFor="extraDevices">Дополнительные устройства</label>
                    <input
                        placeholder='Пожалуйста, пишите на русском языке.'
                        type="text"
                        id="extraDevices"
                        name="extraDevices"
                        value={formData.extraDevices}
                        onChange={handleChange}
                    />
                </div>
                <div className="formGroup flex">
                    <label htmlFor="description">Описание</label>
                    <textarea
                        placeholder='Пожалуйста, пишите на русском языке.'
                        type="text"
                        id="description"
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                    ></textarea>
                </div>
                <div className="formGroup flex">
                    <label htmlFor="guaranteePeriod">Срок гарантии</label>
                    <input
                        placeholder='Пожалуйста, пишите на русском языке.'
                        type="text"
                        id="guaranteePeriod"
                        name="guaranteePeriod"
                        value={formData.guaranteePeriod}
                        onChange={handleChange}
                    />
                </div>
                <div className="formGroup flex">
                    <label htmlFor="madeIn">Сделано в</label>
                    <input
                        placeholder='Пожалуйста, пишите на русском языке.'
                        type="text"
                        id="madeIn"
                        name="madeIn"
                        value={formData.madeIn}
                        onChange={handleChange}
                    />
                </div>
                <div className="formGroup flex checkbox-wrapper">
                    <label htmlFor="paymentContract">Договор оплаты</label>
                    <label className="rocker rocker-small">
                        <input
                            type="checkbox"
                            id="paymentContract"
                            name="paymentContract"
                            checked={formData.paymentContract}
                            onChange={handleChange}
                        />
                        <span className="switch-left">Да</span>
                        <span className="switch-right">Нет</span>
                    </label>
                </div>
                <div className="formGroup flex checkbox-wrapper">
                    <label htmlFor="service">Обслуживание</label>
                    <label className="rocker rocker-small">
                        <input
                            type="checkbox"
                            id="service"
                            name="service"
                            checked={formData.service}
                            onChange={handleChange}
                        />
                        <span className="switch-left">Да</span>
                        <span className="switch-right">Нет</span>
                    </label>
                </div>
                <h4 className='dataInfo'>{noFreePlease}</h4>
                <h4 className='dataInfo'>{dataInfo}</h4>
                <button type="submit">Следующий</button>
            </form>
        </div>
    );
}