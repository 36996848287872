import React, { useEffect, useState } from 'react';
import Navbar from './components/Navbar/Navbar';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home/Home';
import { Contexts } from './context/Contexts';
import News from './pages/News/News';
import About from './pages/About/About';
import Contact from './pages/Contact/Contact';
import PageNotFound from './pages/404/PageNotFound';
import Footer from './components/Footer/Footer';
import ProductsPage from './pages/ProductsPage/ProductsPage';
import ProductInfo from './pages/ProductInfo/ProductInfo';
import ScrollToTop from './utilits/ScrollToTop';
import SearchModal from './components/SearchModal/SearchModal';
import SearchResults from './pages/SearchResults/SearchResults';
import NewsInfo from './pages/NewsInfo/NewsInfo';
import AdminPage from './pages/AdminPage/AdminPage';
import ProtectedRoutes from './components/ProtectedRoutes/ProtectedRoutes';
import LoginModal from './components/LoginModal/LoginModal';
import Loading from './components/Loading/Loading';
import { useGetAllProductsUzbQuery } from './redux/ProductUzbApi';
import { useGetAllProductsRusQuery } from './redux/ProductRusApi';
import { useGetAllProductsEngQuery } from './redux/ProductEngApi';
import { useGetAllNewsEngQuery } from './redux/NewsEngApi';
import { useGetAllNewsRusQuery } from './redux/NewsRusApi';
import { useGetAllNewsUzbQuery } from './redux/NewsUzbApi';

export default function App() {

  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(20000000);

  const {
    data: EngData,
    error: errorEng,
    isLoading: isLoadingEng,
    isSuccess: isSuccessEng
  } = useGetAllProductsEngQuery({ pageSize: pageSize, pageIndex: pageIndex });
  const {
    data: RusData,
    error: errorRus,
    isLoading: isLoadingRus,
    isSuccess: isSuccessRus
  } = useGetAllProductsRusQuery({ pageSize: pageSize, pageIndex: pageIndex });
  const {
    data: UzbData,
    error: errorUzb,
    isLoading: isLoadingUzb,
    isSuccess: isSuccessUzb
  } = useGetAllProductsUzbQuery({ pageSize: pageSize, pageIndex: pageIndex });

  // news

  const {
    data: newsEngData,
    isLoading: newsEngIsLoading,
    isSuccess: newsEngIsSuccess,
    error: newsEngError
  } = useGetAllNewsEngQuery({ pageSize: 100, pageIndex: 1 })
  const {
    data: newsRusData,
    isLoading: newsRusIsLoading,
    isSuccess: newsRusIsSuccess,
    error: newsRusError
  } = useGetAllNewsRusQuery({ pageSize: 100, pageIndex: 1 })
  const {
    data: newsUzbData,
    isLoading: newsUzbIsLoading,
    isSuccess: newsUzbIsSuccess,
    error: newsUzbError
  } = useGetAllNewsUzbQuery({ pageSize: 100, pageIndex: 1 })

  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [truncateLength, setTruncateLength] = useState(29);
  const [activeComponent, setActiveComponent] = useState('specifications');
  const [activeProductComponent, setActiveProductComponent] = useState('addProductEng');
  const [activeNewsComponent, setActiveNewsCoponent] = useState('createNewsComponent')
  const [modelName, setModelName] = useState('');
  const [languageRu, setLanguageRu] = useState(() => JSON.parse(localStorage.getItem('languageRu')) || false);
  const [languageUz, setLanguageUz] = useState(() => JSON.parse(localStorage.getItem('languageUz')) || false);
  const [languageEng, setLanguageEng] = useState(() => JSON.parse(localStorage.getItem('languageEng')) || false);
  const [isOpenMap, setIsOpenMap] = useState(false);
  const [isLogin, setIsLogin] = useState(() => JSON.parse(sessionStorage.getItem('isLogin')) || false);
  const [openLoading, setOpenLoading] = useState(false);
  const [ordersModal, setOrdersModal] = useState(false)
  const [navHeight, setNavHeight] = useState(0);
  const [customerName, setCustomerName] = useState('');
  const [customerNumber, setCustomerNumber] = useState('');
  const [newsFileOpen, setNewsFileOpen] = useState(false)

  // const [companyPosition, setCompanyPosition] = useState([])

  const updateTruncateLength = () => {
    if (window.innerWidth <= 576) {
      setTruncateLength(10);
    }
  };

  useEffect(() => {
    updateTruncateLength();
    window.addEventListener('resize', updateTruncateLength);
    return () => {
      window.removeEventListener('resize', updateTruncateLength);
    };
  }, [truncateLength]);

  useEffect(() => {
    if (!(localStorage.getItem('languageRu') || localStorage.getItem('languageUz') || localStorage.getItem('languageEng'))) {
      localStorage.setItem('languageUz', JSON.stringify(true));
    }

    setLanguageEng(localStorage.getItem('languageEng') === 'true');
    setLanguageRu(localStorage.getItem('languageRu') === 'true');
    setLanguageUz(localStorage.getItem('languageUz') === 'true');
  }, []);

  useEffect(() => {
    if (languageEng && isLoadingEng) {
      setOpenLoading(true)
    } else if (languageRu && isLoadingRus) {
      setOpenLoading(true)
    } else if (languageUz && isLoadingUzb) {
      setOpenLoading(true)
    } else if (languageEng && newsEngIsLoading) {
      setOpenLoading(true)
    } else if (languageRu && newsRusIsLoading) {
      setOpenLoading(true)
    } else if (languageUz && newsUzbIsLoading) {
      setOpenLoading(true)
    } else {
      setOpenLoading(false)
    }
  }, [isLoadingEng,
    isLoadingRus,
    isLoadingUzb,
    languageEng,
    languageRu,
    languageUz,
    newsEngIsLoading,
    newsRusIsLoading,
    newsUzbIsLoading])

  const [prevLanguages, setPrevLanguages] = useState({
    languageEng,
    languageRu,
    languageUz,
  });

  useEffect(() => {
    if (
      prevLanguages.languageEng !== languageEng ||
      prevLanguages.languageRu !== languageRu ||
      prevLanguages.languageUz !== languageUz
    ) {
      setOpenLoading(true);

      const timer = setTimeout(() => {
        setOpenLoading(false);
      }, 300);

      setPrevLanguages({
        languageEng,
        languageRu,
        languageUz,
      });

      return () => clearTimeout(timer);
    }
  }, [languageEng, languageRu, languageUz]);

  const options = {
    loop: true,
    responsive: {
      0: {
        items: 1,
      },
    },
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
  };

  return (
    <Contexts.Provider
      value={{
        options,
        openSearchModal,
        setOpenSearchModal,
        truncateLength,
        activeComponent,
        setActiveComponent,
        languageRu,
        setLanguageRu,
        languageUz,
        setLanguageUz,
        languageEng,
        setLanguageEng,
        isOpenMap,
        setIsOpenMap,
        isLogin,
        setIsLogin,
        activeProductComponent,
        setActiveProductComponent,
        openLoading,
        setOpenLoading,
        modelName,
        setModelName,
        ordersModal,
        setOrdersModal,
        navHeight,
        setNavHeight,
        EngData,
        RusData,
        UzbData,
        isLoadingEng,
        isLoadingRus,
        isLoadingUzb,
        errorEng,
        errorRus,
        errorUzb,
        isSuccessEng,
        isSuccessRus,
        isSuccessUzb,
        newsEngData,
        newsUzbData,
        newsRusData,
        newsEngIsLoading,
        newsRusIsLoading,
        newsUzbIsLoading,
        newsEngIsSuccess,
        newsRusIsSuccess,
        newsUzbIsSuccess,
        pageIndex,
        setPageIndex,
        pageSize,
        setPageSize,
        customerName,
        setCustomerName,
        customerNumber,
        setCustomerNumber,
        newsFileOpen,
        setNewsFileOpen,
        activeNewsComponent,
        setActiveNewsCoponent,

        // companyPosition,
        // setCompanyPosition,
      }}
    >
      {openLoading && <Loading />}
      <Navbar />
      <ScrollToTop />
      <SearchModal />

      {/* <MapComponent position={companyPosition} /> */}

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/news" element={<News />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/productsPage" element={<ProductsPage />} />
        <Route path="/productInfo/:id" element={<ProductInfo />} />
        <Route path="/searchResults" element={<SearchResults />} />
        <Route path="/newsInfo/:id" element={<NewsInfo />} />
        <Route path="/*" element={<PageNotFound />} />
        <Route path="login" element={<LoginModal />} />
        <Route element={<ProtectedRoutes />}>
          <Route path="/thisPAGEisOnLyFoRaDmInS" element={<AdminPage />} />
        </Route>
      </Routes>
      <Footer />
    </Contexts.Provider>
  );
}