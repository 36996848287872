import React, { useContext } from 'react';
import './Description.css';
import { Contexts } from '../../context/Contexts';

export default function Description({ description }) {
    const { languageUz, languageRu, languageEng } = useContext(Contexts);

    const getTitle = () => {
        if (languageEng) return 'Description';
        if (languageRu) return 'Общее описание';
        if (languageUz) return 'Umumiy tavfsif';
    };

    return (
        <div className='description'>
            <div className="txt">
                <h4 className="title">
                    {getTitle()}
                </h4>
                <p className='subTitle'>
                    {description}
                </p>
            </div>
        </div>
    );
}