import React, { useContext, useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Contexts } from '../../context/Contexts';
import search from '../../img/search.svg';
import defaultImage from '../../img/Frame 270.svg';
import { useNavigate } from 'react-router-dom';
import { useGetAllProductsEngQuery } from '../../redux/ProductEngApi';

const style = {
    position: 'absolute',
    top: '130px',
    right: '140px',
    width: 695,
    height: 'auto',
    bgcolor: 'background.paper',
    outline: 'none',
    borderRadius: '16px',
    padding: '40px 35px',
};

const modalContentStyle = {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
};

const inputStyle = {
    width: '100%',
    border: "1px solid rgba(0, 172, 179, 1)",
    padding: '12px 16px',
    borderRadius: '8px',
    outline: 'none',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
    color: 'rgba(42, 51, 74, 1)'
};

const searchButtonStyle = {
    position: 'absolute',
    display: 'flex',
    top: '50%',
    right: 0,
    transform: 'translateY(-50%)',
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    marginRight: '16px'
};

const modalContentFind = {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '8px'
};

const modalContentFindImg = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '60px'
};

const triangleStyle = {
    position: 'absolute',
    top: '113px',
    right: '200px',
    width: 0,
    height: 0,
    borderTop: '30px solid transparent',
    borderBottom: '30px solid white',
    borderRight: '30px solid transparent',
    zIndex: '11111111111',
    transform: 'rotate(135deg)',
};

const modalStyles = {
    width: '100%',
    maxWidth: 1440,
    margin: 'auto',
};

const boxStyle = {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    columnGap: '22px',
    padding: '8px 16px',
    boxShadow: '0px 1px 8px 0px rgba(0, 172, 179, 0.12)',
    borderRadius: '8px',
};

const titleStyle = {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
    cursor: 'pointer',
};

const imgBoxStyle = {
    width: '50px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0px 0.25393083691596985px 2.031446695327759px 0px rgba(0, 172, 179, 0.12)',
    border: '0.25px solid rgba(0, 172, 179, 1)',
    borderRadius: '2px',
    overflow: 'hidden',
    padding: '1px',
};

const imageStyle = {
    width: '100%',
    height: '100%',
    cursor: 'pointer',
};

const firstBoxStyle = {
    marginTop: '16px',
};

const buttonStyle = {
    backgroundColor: 'rgba(0, 172, 179, 1)',
    border: 'none',
    borderRadius: '8px',
    fontSize: '16px',
    lineHeight: '24px',
    padding: '10px 20px',
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    cursor: 'pointer',
    color: 'white'
}

export default function SearchModal() {
    const { openSearchModal, setOpenSearchModal, EngData, isLoadingEng, languageUz, languageRu, languageEng } = useContext(Contexts);
    const [searchResults, setSearchResults] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const inputRef = useRef(null);

    const navigate = useNavigate();

    const getText = (uzText, ruText, engText) => {
        if (languageUz) return uzText;
        if (languageRu) return ruText;
        if (languageEng) return engText;
    };

    const handleClose = () => {
        setOpenSearchModal(false);
        setSearchQuery('');
    };

    useEffect(() => {
        if (openSearchModal) {
            setTimeout(() => {
                inputRef.current.focus();
            }, 0);
        }
    }, [openSearchModal]);

    const handleSearch = (query) => {
        setSearchQuery(query);
        if (query.trim() === '') {
            setSearchResults([]);
            return;
        }
        const searchResults = EngData?.filter(product =>
            product?.modelName?.toLowerCase().includes(query.toLowerCase())
        );
        setSearchResults(searchResults);
    };

    const visibleProducts = searchResults?.slice(0, 4);

    const handleToPage = (id) => {
        navigate(`/productInfo/${id}`);
        setOpenSearchModal(false);
        setSearchQuery('');
    };

    const handleSeeAll = (e) => {
        e.preventDefault();
        navigate(`/searchResults?query=${encodeURIComponent(searchQuery)}`);
        setOpenSearchModal(false);
        setSearchQuery('');
    };

    return (
        <div className='searchModal'>
            <Modal
                style={modalStyles}
                open={openSearchModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box>
                    {openSearchModal && <div className='triangle' style={triangleStyle}></div>}
                    <Box sx={style}>
                        <form className="modalContent" style={modalContentStyle} onSubmit={handleSeeAll}>
                            <input
                                ref={inputRef}
                                type="text"
                                style={inputStyle}
                                placeholder="Search"
                                value={searchQuery}
                                onChange={(e) => handleSearch(e.target.value)}
                                disabled={isLoadingEng && 'disabled'}
                            />
                            <button
                                style={searchButtonStyle}
                            >
                                <img src={search} alt="" />
                            </button>
                        </form>
                        {searchQuery.trim() !== '' ? (
                            <div className='modalContentFind' style={modalContentFind}>
                                {searchResults?.length > 0 ? (
                                    <>
                                        {visibleProducts?.map((product, index) => (
                                            <div className='box' key={product.id} style={index === 0 ? { ...boxStyle, ...firstBoxStyle } : boxStyle}>
                                                <div onClick={() => handleToPage(product.id)} className="img" style={imgBoxStyle}>
                                                    <img src={product.filePath} alt="" style={imageStyle} />
                                                </div>
                                                <h4 onClick={() => handleToPage(product.id)} style={titleStyle}>
                                                    {product?.modelName?.length > 50 ? product?.modelName?.slice(0, 50) + '...' : product.modelName}
                                                </h4>
                                            </div>
                                        ))}
                                        <div className="btnBox">
                                            <button
                                                className='seeAll'
                                                style={buttonStyle}
                                                onClick={handleSeeAll}
                                            >
                                                {getText("Hammasini ko'rish", "Посмотреть все", "See All")}
                                            </button>
                                        </div>
                                    </>
                                ) : (
                                    <div className="img" style={modalContentFindImg}>
                                        <img src={defaultImage} alt="Изображение по умолчанию" />
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div className="img" style={modalContentFindImg}>
                                <img src={defaultImage} alt="Изображение по умолчанию" />
                            </div>
                        )}
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}