import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseQueryWithAuth = fetchBaseQuery({
    baseUrl: "http://45.138.158.239:5002/api/",
    prepareHeaders: (headers) => {
        const token = localStorage.getItem('token');
        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        }
        headers.set('Content-Type', 'application/json');
        return headers;
    },
    responseHandler: async (response) => {
        if (!response.ok) {
            const error = await response.text();
            throw new Error(error || response.statusText);
        }
        const contentType = response.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
            return response.json();
        } else {
            return response.text();
        }
    },
});

export const productRusApi = createApi({
    reducerPath: 'productRusApi',
    baseQuery: baseQueryWithAuth,
    tagTypes: ['Product'],
    endpoints: (builder) => ({
        createProductRus: builder.mutation({
            query: (product) => ({
                url: 'ProductRus/CreatProductRus',
                method: 'POST',
                body: product,
            }),
            invalidatesTags: [{ type: 'Product', id: 'LIST' }],
        }),
        updateProductRus: builder.mutation({
            query: ({ id, product }) => ({
                url: `ProductRus/UpdateProductRus?id=${id}`,
                method: 'PUT',
                body: product,
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'Product', id }],
        }),
        getProductRusById: builder.query({
            query: (id) => `ProductRus/GetProductRus?id=${id}`,
            providesTags: (result, error, id) => [{ type: 'Product', id }],
        }),
        getAllProductsRus: builder.query({
            query: ({ pageSize, pageIndex }) =>
                `ProductRus/GetAllProductRus?pageSize=${pageSize}&pageIndex=${pageIndex}`,
            providesTags: (result) =>
                result ?
                    [...result.map(({ id }) => ({ type: 'Product', id })), { type: 'Product', id: 'LIST' }]
                    : [{ type: 'Product', id: 'LIST' }],
        }),
        getAllProductsRusByBrandName: builder.query({
            query: ({ pageSize, pageIndex, brandName }) =>
                `ProductRus/GetAllProductByBrandNameRus?pageSize=${pageSize}&pageIndex=${pageIndex}&brandName=${brandName}`,
            providesTags: (result) =>
                result ?
                    [...result.map(({ id }) => ({ type: 'Product', id })), { type: 'Product', id: 'LIST' }]
                    : [{ type: 'Product', id: 'LIST' }],
        }),
        deleteProductRus: builder.mutation({
            query: (id) => ({
                url: `ProductRus/DeleteProductAll?id=${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, id) => [{ type: 'Product', id }, { type: 'Product', id: 'LIST' }],
        }),
    }),
});

export const {
    useCreateProductRusMutation,
    useUpdateProductRusMutation,
    useGetProductRusByIdQuery,
    useGetAllProductsRusQuery,
    useGetAllProductsRusByBrandNameQuery,
    useDeleteProductRusMutation,
} = productRusApi;