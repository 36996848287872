import React, { useContext } from 'react';
import './Reviews.css';
import AdReviews from '../AdReviews/AdReviews';
import { Contexts } from '../../context/Contexts';

export default function Reviews({ data }) {
    const { languageUz, languageRu, languageEng } = useContext(Contexts);

    const getText = (uzText, ruText, engText) => {
        if (languageUz) return uzText;
        if (languageRu) return ruText;
        if (languageEng) return engText;
    };

    return (
        <>
            <AdReviews productModel={data?.modelName} marginBottom={data?.comments?.length} />
            {data?.comments?.length !== 0 &&
                <div className='reviews'>
                    <div className="reviewsTop flex padding">
                        <h3>{getText('Sharhlar', 'Отзывы', 'Reviews')}</h3>
                    </div>
                    <div className="reviewsBottom padding">
                        {data?.comments?.map((comment, index) => {
                            const nameParts = comment?.name?.split(' ');
                            const initials = nameParts?.length > 1
                                ? `${nameParts[0].charAt(0).toUpperCase()}${nameParts[nameParts.length - 1].charAt(0).toUpperCase()}`
                                : nameParts[0].charAt(0).toUpperCase();

                            return (
                                <div key={index} className="commentsBody">
                                    <div className="commentsBodyTop flex">
                                        <div className="round flex">
                                            <p>{initials}</p>
                                        </div>
                                        <p className="name">{comment?.name}</p>
                                    </div>
                                    <div className="commentsBodyBottom">
                                        <p className='comment'>{comment?.comment}</p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            }
        </>
    );
}