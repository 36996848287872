import React, { useContext } from 'react';
import './Products.css';
import { Contexts } from '../../context/Contexts';
import { Link, useNavigate } from 'react-router-dom';

export default function Products() {
    const {
        languageUz,
        languageRu,
        languageEng,
        EngData,
        RusData,
        UzbData,
        isLoadingEng,
        isLoadingRus,
        isLoadingUzb,
        errorEng,
        errorRus,
        errorUzb,
        truncateLength,
    } = useContext(Contexts);

    const navigate = useNavigate();

    const renderProducts = (data) => {
        return data?.slice(0, 16)?.map((product) => (
            <div key={product.id} className="col-3 col-xs-6">
                <div className="box">
                    <div onClick={() => navigate(`/productInfo/${product.id}`)} className="carouselImg">
                        <img src={product.filePath} alt={product.modelName} onError={(e) => console.log("Error loading image:", e.target.src)} />
                    </div>
                    <div className="text">
                        <p onClick={() => navigate(`/productInfo/${product.id}`)}>
                            <span>{product.modelName?.length > truncateLength ? `${product.modelName?.slice(0, truncateLength)}...` : product.modelName}</span>
                        </p>
                        <p>
                            <span>{product.brandName?.length > truncateLength ? `${product.brandName?.slice(0, truncateLength)}...` : product.brandName}</span>
                        </p>
                        <p><span>{product?.produced}</span></p>
                    </div>
                </div>
            </div>
        ));
    };

    const renderSection = (language, data, isLoading, error, header, notFoundText, seeAllText) => (
        <>
            <h2>{header}</h2>
            {error && <h3>{error}</h3>}
            <div className="row">
                {isLoading ? (
                    <p>Loading...</p>
                ) : (
                    data?.length > 0 ? renderProducts(data) : <p>{notFoundText}</p>
                )}
            </div>
            <Link className='seeAll' to={'/productsPage'}>
                {seeAllText}
            </Link>
        </>
    );

    return (
        <div className='products'>
            <div className="container">
                {languageUz && renderSection(
                    languageUz, UzbData, isLoadingUzb, errorUzb, 'Top Maxsulotlar', 'Mahsulot topilmadi', 'Barchasini ko`rish'
                )}
                {languageEng && renderSection(
                    languageEng, EngData, isLoadingEng, errorEng, 'Top Products', 'Product not found', 'See all'
                )}
                {languageRu && renderSection(
                    languageRu, RusData, isLoadingRus, errorRus, 'Топ Продукты', 'Товар не найден', 'Посмотреть все'
                )}
            </div>
        </div>
    );
}