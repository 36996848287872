import React, { useContext, useEffect, useState } from 'react';
import './ProductInfo.css';
import { Link, useParams } from 'react-router-dom';
import { Contexts } from '../../context/Contexts';
import { ReactSVG } from 'react-svg';
import arrow from '../../img/direction-left 01.svg';
import car from '../../img/image 63.png';
import settings from '../../img/image 63-1.png';
import man from '../../img/image 63-2.png';
import shield from '../../img/image 63-3.png';
import Specifications from '../../components/Specifications/Specifications';
import Description from '../../components/Description/Description';
import Reviews from '../../components/Reviews/Reviews';
import Delivery from '../../components/Delivery/Delivery';
import FirstReview from '../../components/FirstReview/FirstReview';
import Loading from '../../components/Loading/Loading';
import OrdersModal from '../../components/OrdersModal/OredesModal';
import { useGetProductEngByIdQuery } from '../../redux/ProductEngApi';
import { useGetProductUzbByIdQuery } from '../../redux/ProductUzbApi';
import { useGetProductRusByIdQuery } from '../../redux/ProductRusApi';

export default function ProductInfo() {
    const { id } = useParams();

    const { data: productEngData, isLoading: isLoadingEng } = useGetProductEngByIdQuery(id);
    const { data: productUzbData, isLoading: isLoadingUzb } = useGetProductUzbByIdQuery(id);
    const { data: productRusData, isLoading: isLoadingRus } = useGetProductRusByIdQuery(id);

    const {
        activeComponent,
        setActiveComponent,
        languageUz,
        languageRu,
        languageEng,
        setOpenLoading,
        setOrdersModal,
        navHeight,
        truncateLength
    } = useContext(Contexts);

    const [selectedImageIndex, setSelectedImageIndex] = useState(0);

    const productData = languageUz ? productUzbData : languageEng ? productEngData : productRusData;
    const isLoading = languageUz ? isLoadingUzb : languageEng ? isLoadingEng : isLoadingRus;

    const images = [
        productData?.files[0]?.filePath,
        productData?.files[1]?.filePath,
        productData?.files[2]?.filePath,
    ];

    const handlePrevClick = () => {
        setSelectedImageIndex((prevIndex) => (prevIndex - 1 + images?.length) % images?.length);
    };

    const handleNextClick = () => {
        setSelectedImageIndex((prevIndex) => (prevIndex + 1) % images?.length);
    };

    const handleSpecificationsClick = () => {
        setActiveComponent('specifications');
    };

    const handleDescriptionClick = () => {
        setActiveComponent('description');
    };

    const handleReviewsClick = () => {
        if (productData?.comments?.length === 0) {
            setActiveComponent('firstReview')
        } else {
            setActiveComponent('reviews');
        }
    };

    const handleDeliveryClick = () => {
        setActiveComponent('delivery');
    };

    const handleOpenModal = (e) => {
        e.preventDefault();
        setOrdersModal(true);
    };

    useEffect(() => {
        setOpenLoading(isLoading);
    }, [isLoading, setOpenLoading]);

    const getText = (uzText, engText, ruText) => languageUz ? uzText : languageEng ? engText : ruText;

    return (
        <>
            <div className='productInfo navPositionFixed' style={{ marginTop: `${navHeight}px` }}>
                <div className="container">
                    <div className="pageTop">
                        <Link to={'/'}>
                            {getText('Bosh sahifa', 'Home', 'Главная страница')}
                        </Link>
                        <ReactSVG src={arrow} className='mySvg' />
                        <Link to={'/productsPage'}>
                            {getText('Mahsulotlar', 'Products', 'Продукты')}
                        </Link>
                        <ReactSVG src={arrow} className='mySvg' />
                        <p>
                            {productData?.modelName?.length > truncateLength ? `${productData?.modelName?.slice(0, truncateLength)}...` : productData?.modelName}
                        </p>
                    </div>
                    <div className="top">
                        <div className="row">
                            <div className="col-6 col-xs-12">
                                <div className="row imgBox">
                                    <div className="col-12">
                                        <div className="imgBig">
                                            {selectedImageIndex != null && <img src={images[selectedImageIndex]} alt="Selected" />}
                                        </div>
                                    </div>
                                    <div className="imgBottom">
                                        {images.map((img, index) => (
                                            <div className="col-4" key={index}>
                                                <div className="img" onClick={() => setSelectedImageIndex(index)}>
                                                    <img src={img} alt={`Image ${index + 1}`} />
                                                </div>
                                            </div>
                                        ))}
                                        <div className="btnGroup">
                                            <div className="btn prev" onClick={handlePrevClick}><ReactSVG src={arrow} className='mySvg' /></div>
                                            <div className="btn next" onClick={handleNextClick}><ReactSVG src={arrow} className='mySvg' /></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-xs-12">
                                <div className="textBox">
                                    <h3 className='model'>
                                        {getText(`Model: ${productData?.modelName}`, `Model: ${productData?.modelName}`, `Модель: ${productData?.modelName}`)}
                                    </h3>
                                    <h3 className='desc'>
                                        {productData?.description}
                                    </h3>
                                    <div className="infoBox flex">
                                        <div className="box">
                                            <img src={car} alt="" />
                                            <p>{getText('Tez yetkazib berish', 'Fast delivery', 'Быстрая доставка')}</p>
                                        </div>
                                        <div className="box">
                                            <img src={settings} alt="" />
                                            <p>{getText('Bepul o`rnatib berish', 'Free installation', 'Бесплатная установка')}</p>
                                        </div>
                                        <div className="box">
                                            <img src={man} alt="" />
                                            <p>{getText('Bepul xizmat ko`rsatish', 'Free service', 'Бесплатный сервис')}</p>
                                        </div>
                                        <div className="box shield">
                                            <img src={shield} alt="" />
                                            <p>{getText('Uzoq muddatli kafolat', 'Long term warranty', 'Долгосрочная гарантия')}</p>
                                        </div>
                                    </div>
                                    <div className="bottomBox flex">
                                        <div className="box">
                                            <h4>{getText('Brend', 'Brand', 'Бренд')}</h4>
                                            <p>{productData?.brandName}</p>
                                        </div>
                                        <div className="box">
                                            <h4>{getText('Mamlakat', 'Country', 'Страна')}</h4>
                                            <p>{productData?.madeIn}</p>
                                        </div>
                                        <div className="box">
                                            <h4>{getText('Kafolati', 'Warranty', 'Гарантия')}</h4>
                                            <p>{productData?.guaranteePeriod}</p>
                                        </div>
                                    </div>
                                    <button className='buyBtn' onClick={handleOpenModal}>
                                        {getText('Buyurtma qilish', 'To order', 'Заказать')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bottom">
                        <div className="row btnBox">
                            <div className="col-3 col-xs-6">
                                <button className={`btn ${activeComponent === 'specifications' ? 'active' : ''}`} onClick={handleSpecificationsClick}>
                                    {getText('Qo`shimcha qurilmalar', 'Extra devices', 'Дополнительные устройства')}
                                </button>
                            </div>
                            <div className="col-3 col-xs-6">
                                <button className={`btn ${activeComponent === 'description' ? 'active' : ''}`} onClick={handleDescriptionClick}>
                                    {getText('Texnik tavsifi', 'Description', 'Техническое описание')}
                                </button>
                            </div>
                            <div className="col-3 col-xs-6">
                                <button className={`btn ${activeComponent === 'reviews' || activeComponent === 'firstReview' ? 'active' : ''}`} onClick={handleReviewsClick}>
                                    {getText('Mijozlarning sharhlari', 'Customer Reviews', 'Отзывы клиентов')}
                                </button>
                            </div>
                            <div className="col-3 col-xs-6">
                                <button className={`btn ${activeComponent === 'delivery' ? 'active' : ''}`} onClick={handleDeliveryClick}>
                                    {getText('Yetkazib berish va to`lash', 'Delivery and payment', 'Доставка и оплата')}
                                </button>
                            </div>
                        </div>

                        {activeComponent === 'specifications' && <Specifications extraDevices={productData?.extraDevices} />}
                        {activeComponent === 'description' && <Description description={productData?.description} />}
                        {activeComponent === 'firstReview' && <FirstReview />}
                        {activeComponent === 'reviews' && <Reviews data={productData} />}
                        {activeComponent === 'delivery' && <Delivery paymentContract={productData?.paymentContract}
                            paymentType={productData?.paymentType} />
                        }
                    </div>
                </div>
            </div>
            <OrdersModal modelName={productData?.modelName} />
        </>
    );
}