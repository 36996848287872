export const menu = [
    {
        linkName: 'Bosh sahifa', slug: '/'
    },
    {
        linkName: 'Mahsulotlar', slug: '/productsPage'
    },
    {
        linkName: 'Yangiliklar', slug: '/news'
    },
    {
        linkName: 'Biz haqimizda', slug: '/about'
    },
    {
        linkName: 'Aloqa', slug: '/contact'
    },
 ];

export const menuRu = [
    {
        linkName: 'Главная страница', slug: '/'
    },
    {
        linkName: 'Продукты', slug: '/productsPage'
    },
    {
        linkName: 'Новости', slug: '/news'
    },
    {
        linkName: 'О нас', slug: '/about'
    },
    {
        linkName: 'Контакты ', slug: '/contact'
    },
];

export const menuEng = [
    {
        linkName: 'Home', slug: '/'
    },
    {
        linkName: 'Products', slug: '/productsPage'
    },
    {
        linkName: 'News', slug: '/news'
    },
    {
        linkName: 'About Us', slug: '/about'
    },
    {
        linkName: 'Contact Us ', slug: '/contact'
    },
];