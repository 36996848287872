import React, { useState } from 'react';
import './ChangePasswordComponent.css';
import {  useUpdateAdminMutation } from '../../redux/AdminApi';

export default function ChangePasswordComponent({ admins }) {
    const [email, setEmail] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');

    const [updateAdmin, { error }] = useUpdateAdminMutation();

    const handleSubmit = async (e) => {
        e.preventDefault();

        const adminToUpdate = admins.find(admin => admin.email === email);
        if (!adminToUpdate) {
            alert('Администратор с указанным электронным адресом не найден.');
            return;
        }

        try {
            await updateAdmin({
                id: adminToUpdate?.id,
                email: email,
                newPassword: newPassword,
                oldPassword: oldPassword
            });
            alert('Пароль успешно обновлен.');
            setEmail('');
            setOldPassword('');
            setNewPassword('');
        } catch (error) {
            console.error('Ошибка обновления пароля:', error);
            alert('Произошла ошибка при обновлении пароля. Пожалуйста, попробуйте еще раз.');
        }
    };

    return (
        <div className='changePasswordComponent'>
            <form onSubmit={handleSubmit}>
                <div className="formGroup flex">
                    <label htmlFor="email">Email</label>
                    <input
                        type="text"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <div className="formGroup flex">
                    <label htmlFor="oldPassword">Old Password</label>
                    <input
                        type="password"
                        id="oldPassword"
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                    />
                </div>
                <div className="formGroup flex">
                    <label htmlFor="newPassword">New Password</label>
                    <input
                        type="password"
                        id="newPassword"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                </div>
                <button type="submit">Change Password</button>
            </form>
        </div>
    );
}