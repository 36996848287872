import React, { useState } from 'react';
import './CreateAdminComponent.css';
import { useCreateAdminMutation } from '../../redux/AdminApi';

export default function CreateAdminComponent() {

    const [createAdmin, { isLoading: isCreating, isError: isCreateError, error: createError }] = useCreateAdminMutation();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            alert('Passwords do not match');
            return;
        } else {
            const adminData = { email, password, confirmPassword };
            try {
                await createAdmin(adminData).unwrap();
                alert('Admin created successfully');
                setEmail('')
                setPassword('')
                setConfirmPassword('')
            } catch (error) {
                console.error('Ошибка при создании администратора:', error);
            }
        }
    };

    return (
        <div className='createAdminComponent'>
            <form onSubmit={handleSubmit}>
                <div className="formGroup flex">
                    <label htmlFor="email">Email</label>
                    <input
                        type="text"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <div className="formGroup flex">
                    <label htmlFor="password">Password</label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                <div className="formGroup flex">
                    <label htmlFor="confirmPassword">Confirm Password</label>
                    <input
                        type="password"
                        id="confirmPassword"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                </div>
                <button type="submit" disabled={isCreating}>Create Admin</button>
                {isCreating && <h4 className='dataInfo'>Please wait, admin is being created.</h4>}
                {isCreateError && <h4 className='dataInfo'>{'Error: ' + createError?.data?.errors?.Password}</h4>}
            </form>
        </div>
    );
}