import React, { useContext } from 'react';
import './Specifications.css';
import { Contexts } from '../../context/Contexts';

export default function Specifications({ extraDevices }) {
    const { languageUz, languageRu, languageEng } = useContext(Contexts);

    const getText = (uzText, ruText, engText) => {
        if (languageUz) return uzText;
        if (languageRu) return ruText;
        if (languageEng) return engText;
    };

    return (
        <div className='specifications'>
            {extraDevices === '' ? (
                <h2>
                    {getText('Xech qanday malumot topilmadi.', 'Никакой информации не найдено.', 'No information found.')}
                </h2>
            ) : (
                <div className="box">
                    <p className="title">{getText('Qo`shimcha qurilmalar', 'Дополнительные устройства', 'Extra devices')}</p>
                    <p className="subTitle">{extraDevices}</p>
                </div>
            )}
        </div>
    );
}