import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseQueryWithAuth = fetchBaseQuery({
    baseUrl: "http://45.138.158.239:5002/api/",
    prepareHeaders: (headers) => {
        const token = localStorage.getItem('token');
        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        }
        headers.set('Content-Type', 'application/json');
        return headers;
    },
    responseHandler: async (response) => {
        const text = await response.text();
        try {
            return JSON.parse(text);
        } catch (error) {
            if (!response.ok) {
                throw new Error(text);
            }
            return text;
        }
    },
});

export const newsRusApi = createApi({
    reducerPath: 'newsRusApi',
    baseQuery: baseQueryWithAuth,
    tagTypes: ['News'],
    endpoints: (builder) => ({
        createNewRus: builder.mutation({
            query: (news) => ({
                url: '/NewsRus/CreatNewEng',
                method: 'POST',
                body: news,
            }),
            invalidatesTags: [{ type: 'News', id: 'LIST' }],
        }),
        updateNewRus: builder.mutation({
            query: (news) => ({
                url: `/NewsRus/UpdateNewRus`,
                method: 'PUT',
                body: news,
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'News', id }],
        }),
        getNewRusById: builder.query({
            query: (id) => `/NewsRus/GetNewRus?id=${id}`,
            providesTags: (result, error, id) => [{ type: 'News', id }],
        }),
        getAllNewsRus: builder.query({
            query: ({ pageSize, pageIndex }) => `/NewsRus/GetAllNewsRus?pageSize=${pageSize}&pageIndex=${pageIndex}`,
            providesTags: (result) =>
                result ?
                    [...result.map(({ id }) => ({ type: 'News', id })), { type: 'News', id: 'LIST' }]
                    : [{ type: 'News', id: 'LIST' }],
        }),
    }),
});

export const {
    useCreateNewRusMutation,
    useUpdateNewRusMutation,
    useGetNewRusByIdQuery,
    useGetAllNewsRusQuery
} = newsRusApi;
