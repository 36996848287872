import React, { useContext } from 'react';
import './TopFour.css';
import { Contexts } from '../../context/Contexts';
import { useNavigate } from 'react-router-dom';

export default function TopFour() {
  const {
    truncateLength,
    languageRu,
    languageUz,
    languageEng,
    EngData,
    RusData,
    UzbData,
  } = useContext(Contexts);

  const navigate = useNavigate();

  const renderProducts = (data) => {
    return data?.slice(0, 4)?.map((product) => (
      <div key={product.id} className="col-3 col-xs-6">
        <div className="box">
          <div onClick={() => navigate(`/productInfo/${product.id}`)} className="carouselImg">
            <img src={product.filePath} alt={product.modelName} onError={(e) => console.log("Error loading image:", e.target.src)} />
          </div>
          <div className="text">
            <p onClick={() => navigate(`/productInfo/${product.id}`)} className='modelName'>
              <span>{product.modelName?.length > truncateLength ? `${product.modelName?.slice(0, truncateLength)}...` : product.modelName}</span>
            </p>
            <p>
              <span>{product.brandName?.length > truncateLength ? `${product.brandName?.slice(0, truncateLength)}...` : product.brandName}</span>
            </p>
            <p><span>{product?.produced}</span></p>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <div className='top-4'>
      <div className="container">
        {languageUz ? <h2>Top Maxsulotlar</h2> : languageRu ? <h2>Топ Продукты</h2> : <h2>Top Products</h2>}
        <div className="row">
          {languageEng && renderProducts(EngData)}
          {languageRu && renderProducts(RusData)}
          {languageUz && renderProducts(UzbData)}
          {renderProducts.length < 0 && (
            <p>
              {languageUz && 'Mahsulot topilmadi'}
              {languageEng && 'Product not found'}
              {languageRu && 'Товар не найден'}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}