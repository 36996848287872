import React, { useState } from 'react'
import './UpdateProduct.css'
import UpdateProductEng from '../UpdateProductEng/UpdateProductEng'
import UpdateProductRus from '../UpdateProductRus/UpdateProductRus'
import UpdateProductUzb from '../UpdateProductUzb/UpdateProductUzb'

export default function UpdateProduct() {

    const [activeComponent, setActiveCoponent] = useState('updateProductEng')

    const handleUpdateProductEngClick = () => {
        setActiveCoponent('updateProductEng')
    }
    const handleUpdateProductRusClick = () => {
        setActiveCoponent('updateProductRus')
    }
    const handleUpdateProductUzbClick = () => {
        setActiveCoponent('updateProductUzb')
    }

    return (
        <div className='updateProduct'>
            <div className="top flex">
                <div
                    className={`btn ${activeComponent === 'updateProductEng' ? 'active' : ''}`}
                    onClick={handleUpdateProductEngClick}
                >
                    <p>English</p>
                </div>
                <div
                    className={`btn ${activeComponent === 'updateProductRus' ? 'active' : ''}`}
                    onClick={handleUpdateProductRusClick}
                >
                    <p>Русский</p>
                </div>
                <div
                    className={`btn ${activeComponent === 'updateProductUzb' ? 'active' : ''}`}
                    onClick={handleUpdateProductUzbClick}
                >
                    <p>O`zbekcha</p>
                </div>
            </div>
            <div className="updateBottom">
                {activeComponent === 'updateProductEng' && <UpdateProductEng />}
                {activeComponent === 'updateProductRus' && <UpdateProductRus />}
                {activeComponent === 'updateProductUzb' && <UpdateProductUzb />}
            </div>
        </div>
    )
}