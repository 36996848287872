import React, { useContext } from 'react'
import './PageNotFound.css'
import { Contexts } from '../../context/Contexts'

export default function PageNotFound() {

    const { navHeight } = useContext(Contexts)

    return (
        <div className='pageNotFound navPositionFixed' style={{ marginTop: `${navHeight}px` }}>
            <div className="container">
                <h1>404</h1>
                <h3>oops.. page not found</h3>
            </div>
        </div>
    )
}
