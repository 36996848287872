import React, { useContext } from 'react';
import './AddProduct.css';
import AddProductEng from '../AddProductEng/AddProductEng';
import AddProductRus from '../AddProductRus/AddProductRus';
import AddProductUzb from '../AddProductUzb/AddProductUzb';
import AddProductFile from '../AddProductFile/AddProductFile';
import { Contexts } from '../../context/Contexts';

export default function AddProduct() {
    const { activeProductComponent, languageUz, languageRu, languageEng } = useContext(Contexts);

    const getInstructionText = () => {
        switch (activeProductComponent) {
            case 'addProductEng':
                return "Please write in English. Don't leave an empty space!";
            case 'addProductRus':
                return 'Пожалуйста, напишите на русском языке. Не оставляйте пустое место!';
            case 'addProductUzb':
                return 'Iltimos, o`zbek tilida yozing. Bo`sh joy qoldimang!';
            case 'addProductFile':
                return languageUz ? '3 ta rasm tanlang!' : languageRu ? 'Выберите 3 картинки!' : 'Choose 3 pictures!';
            default:
                return '';
        }
    };

    const renderActiveComponent = () => {
        switch (activeProductComponent) {
            case 'addProductEng':
                return <AddProductEng />;
            case 'addProductRus':
                return <AddProductRus />;
            case 'addProductUzb':
                return <AddProductUzb />;
            case 'addProductFile':
                return <AddProductFile />;
            default:
                return null;
        }
    };

    return (
        <div className='addProduct'>
            <div className="top flex">
                <div className="writeInfo">
                    {getInstructionText()}
                </div>
            </div>
            <div className="bottom">
                {renderActiveComponent()}
            </div>
        </div>
    );
}