import React, { useContext, useEffect, useState } from 'react';
import './AddProductUzb.css';
import { Contexts } from '../../context/Contexts';
import { useCreateProductUzbMutation } from '../../redux/ProductUzbApi';

export default function AddProductUzb() {
    const [createProductUzb, createProductUzbResult] = useCreateProductUzbMutation();
    const { setActiveProductComponent, modelName, } = useContext(Contexts);

    const [formData, setFormData] = useState({
        brandName: '',
        description: '',
        guaranteePeriod: '',
        madeIn: '',
        produced: '',
        paymentType: '',
        extraDevices: '',
        paymentContract: false,
        service: false,
    });
    const [dataInfo, setDataInfo] = useState('');
    const [noFreePlease, setNoFreePlease] = useState('');

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const { brandName, description, guaranteePeriod, madeIn, produced, paymentType, extraDevices } = formData;

        if ([brandName, description, guaranteePeriod, madeIn, produced, paymentType, extraDevices].some((field) => field === '')) {
            setNoFreePlease("Iltimos, bo`sh qoldirmang!");
            return;
        }

        try {
            const productUzb = {
                modelName,
                ...formData,
            };

            await createProductUzb(productUzb);
            setDataInfo('Mahsulot yuklandi.');
            setActiveProductComponent('addProductFile');
        } catch (error) {
            console.error('Mahsulotni qo`shishda xato:', error);
            setDataInfo('Mahsulot qo`shishda xatolik yuz berdi. Iltimos, yana bir bor urinib ko`ring.');
        }
    };

    useEffect(() => {
        if (createProductUzbResult?.isLoading) {
            setDataInfo("Ma'lumotlar yuklanmoqda.");
        } else if (createProductUzbResult?.isSuccess) {
            setFormData({
                brandName: '',
                description: '',
                guaranteePeriod: '',
                madeIn: '',
                produced: '',
                paymentType: '',
                extraDevices: '',
                paymentContract: false,
                service: false,
            });
            setDataInfo("Ma'lumotlar yuklandi.");
        } else if (createProductUzbResult?.isError) {
            setDataInfo('Xato, qayta urinib ko`ring.');
        }
    }, [createProductUzbResult?.isError, createProductUzbResult?.isLoading, createProductUzbResult?.isSuccess]);

    return (
        <div className='addProductUzb'>
            <form onSubmit={handleSubmit}>
                <div className="formGroup flex">
                    <label htmlFor="brandName">Brend nomi</label>
                    <input
                        placeholder='Iltimos, o`zbek tilida yozing.'
                        type="text"
                        id="brandName"
                        name="brandName"
                        value={formData.brandName}
                        onChange={handleChange}
                    />
                </div>
                <div className="formGroup flex">
                    <label htmlFor="produced">Ishlab chiqarilgan yil</label>
                    <input
                        placeholder='Iltimos, o`zbek tilida yozing.'
                        type="text"
                        id="produced"
                        name="produced"
                        value={formData.produced}
                        onChange={handleChange}
                    />
                </div>
                <div className="formGroup flex">
                    <label htmlFor="paymentType">To`lov turi</label>
                    <input
                        placeholder='Iltimos, o`zbek tilida yozing.'
                        type="text"
                        id="paymentType"
                        name="paymentType"
                        value={formData.paymentType}
                        onChange={handleChange}
                    />
                </div>
                <div className="formGroup flex">
                    <label htmlFor="extraDevices">Qo'shimcha qurilmalar</label>
                    <input
                        placeholder='Iltimos, o`zbek tilida yozing.'
                        type="text"
                        id="extraDevices"
                        name="extraDevices"
                        value={formData.extraDevices}
                        onChange={handleChange}
                    />
                </div>
                <div className="formGroup flex">
                    <label htmlFor="description">Tavsif</label>
                    <textarea
                        placeholder='Iltimos, o`zbek tilida yozing.'
                        type="text"
                        id="description"
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                    ></textarea>
                </div>
                <div className="formGroup flex">
                    <label htmlFor="guaranteePeriod">Kafolat muddati</label>
                    <input
                        placeholder='Iltimos, o`zbek tilida yozing.'
                        type="text"
                        id="guaranteePeriod"
                        name="guaranteePeriod"
                        value={formData.guaranteePeriod}
                        onChange={handleChange}
                    />
                </div>
                <div className="formGroup flex">
                    <label htmlFor="madeIn">Ishlab chiqarilgan davlat</label>
                    <input
                        placeholder='Iltimos, o`zbek tilida yozing.'
                        type="text"
                        id="madeIn"
                        name="madeIn"
                        value={formData.madeIn}
                        onChange={handleChange}
                    />
                </div>
                <div className="formGroup flex checkbox-wrapper">
                    <label htmlFor="paymentContract">To`lov shartnomasi</label>
                    <label className="rocker rocker-small">
                        <input
                            type="checkbox"
                            id="paymentContract"
                            name="paymentContract"
                            checked={formData.paymentContract}
                            onChange={handleChange}
                        />
                        <span className="switch-left">Ha</span>
                        <span className="switch-right">Yo`q</span>
                    </label>
                </div>
                <div className="formGroup flex checkbox-wrapper">
                    <label htmlFor="service">Xizmat ko'rsatish</label>
                    <label className="rocker rocker-small">
                        <input
                            type="checkbox"
                            id="service"
                            name="service"
                            checked={formData.service}
                            onChange={handleChange}
                        />
                        <span className="switch-left">Ha</span>
                        <span className="switch-right">Yo`q</span>
                    </label>
                </div>
                <h4 className='dataInfo'>{noFreePlease}</h4>
                <h4 className='dataInfo'>{dataInfo}</h4>
                <button type="submit">Keyingi</button>
            </form>
        </div>
    );
}