import React, { useEffect, useRef, useState } from 'react';
import './DeleteProduct.css';
import { useDeleteProductEngMutation, useGetAllProductsEngQuery } from '../../redux/ProductEngApi';
import search from '../../img/search.svg';

export default function DeleteProduct() {

    const { data, isError, isLoading, isSuccess } = useGetAllProductsEngQuery({ pageSize: 32, pageIndex: 1 });
    const [deleteProductEng, resultEng] = useDeleteProductEngMutation();

    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [noInfo, setNoInfo] = useState('Please enter the model name.');
    const [productId, setProductId] = useState('');
    const [isClicked, setIsClicked] = useState(false);

    const inputRef = useRef(null);

    useEffect(() => {
        setTimeout(() => {
            inputRef.current.focus();
        }, 0);
    }, []);

    useEffect(() => {
        if (searchQuery.trim() === '') {
            setSearchResults([]);
            setNoInfo('Please enter the model name.');
        } else {
            const results = data?.filter(product =>
                product.modelName.toLowerCase().includes(searchQuery.toLowerCase())
            );
            setSearchResults(results);
            setNoInfo(results.length === 0 ? 'No information found.' : '');
        }
    }, [searchQuery, data]);

    useEffect(() => {
        if (resultEng.isSuccess) {
            setSearchResults(prevResults => prevResults.filter(product =>
                product.id !== productId));
        }
    }, [resultEng, productId]);

    const handleSearch = (query) => {
        setSearchQuery(query);
    };

    const handleSubbmit = (e) => {
        e.preventDefault();
    };

    const handleClick = (id) => {
        setProductId(id);
        setIsClicked(!isClicked);
    };

    const handleDelete = (e) => {
        e.preventDefault();
        deleteProductEng(productId);
        alert('Product is deleted.');
        setSearchQuery('');
        setIsClicked(false);
    };

    return (
        <div className='deleteProduct'>
            <div className="top">
                <form onSubmit={handleSubbmit}>
                    <input
                        ref={inputRef}
                        placeholder='Search'
                        type="text"
                        value={searchQuery}
                        onChange={(e) => handleSearch(e.target.value)}
                    />
                    <button type='submit'>
                        <img src={search} alt="" />
                    </button>
                </form>
            </div>
            <div className="bottom flex">
                {!isLoading && isSuccess && searchResults?.length > 0 ? searchResults?.map((product) =>
                    <div
                        className='box flex'
                        key={product.id}
                    >
                        <div className="img"
                            onClick={() => handleClick(product.id)}
                        >
                            <img src={product.filePath} alt="" />
                        </div>
                        <div className="text"
                            onClick={() => handleClick(product.id)}
                        >
                            <h3>{product.modelName.length > 80 ? product.modelName.slice(0, 80) + '...' : product.modelName}</h3>
                            <p>{product.brandName}</p>
                        </div>
                        {isClicked && productId === product.id &&
                            <div className='deleteButton'>
                                <button
                                    onClick={handleDelete}
                                    className='deleteBtn'>
                                    Delete
                                </button>
                            </div>
                        }
                    </div>
                ) :
                    !isLoading ? <h4 className='noInfo'>{noInfo}</h4> :
                        <h4 className='noInfo'>Please, wait!</h4>
                }
            </div>
        </div>
    );
}