import React, { useState, useContext } from 'react';
import './AdReviews.css';
import { useCreateCommentMutation } from '../../redux/CommentsApi';
import { Contexts } from '../../context/Contexts';

export default function AdReviews({ productModel, marginBottom }) {
    const { languageUz, languageRu, languageEng } = useContext(Contexts);
    const [createComment, { isLoading, isError }] = useCreateCommentMutation();
    const [comment, setComment] = useState('');
    const [name, setName] = useState('');
    const [message, setMessage] = useState('');

    const getText = (uzText, ruText, engText) => {
        if (languageUz) return uzText;
        if (languageRu) return ruText;
        if (languageEng) return engText;
    };

    const handleSubmitReview = async (e) => {
        e.preventDefault();

        try {
            await createComment({ productModel, comment, name }).unwrap();
            setComment('');
            setName('');
            setMessage(getText(
                'Sizning sharhingiz muvaffaqiyatli yuborildi.',
                'Ваш отзыв был успешно отправлен.',
                'Your review has been successfully submitted.'
            ));
        } catch (error) {
            console.error('Ошибка при отправке отзыва:', error);
            setMessage(getText(
                'Sharh yuborishda xatolik yuz berdi. Qayta urinib ko`ring.',
                'Произошла ошибка при отправке отзыва. Попробуйте снова.',
                'An error occurred while submitting the review. Please try again.'
            ));
        }
    };

    return (
        <div className='adReviews' style={marginBottom === 0 ? { marginBottom: '100px' } : {}}>
            <div className="adReviewsTop">
                <h3>{getText('Iltimos, mahsulotimizga sharh qoldiring.', 'Пожалуйста, оставьте отзыв о нашем товаре.', 'Please leave a review about our product.')}</h3>
            </div>
            <div className="adReviewsBottom">
                <form className='flex adReviewsForm' onSubmit={handleSubmitReview}>
                    <label htmlFor="comment">{getText('Sharh', 'Отзыв', 'Review')}</label>
                    <textarea
                        className='comment'
                        id='comment'
                        name='comment'
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        placeholder={getText('Sharhingizni yozing', 'Пишите отзывы', 'Write your review')}
                        required
                    />
                    <div className="flex formBottom">
                        <div className="">
                            <label htmlFor="name">{getText('Ism', 'Имя', 'Name')}</label>
                            <input
                                id='name'
                                name='name'
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                type="text"
                                placeholder={getText('Ismingizni kiriting', 'Введите имя', 'Enter your name')}
                                required
                            />
                        </div>
                    </div>
                    <button type='submit' disabled={isLoading}>
                        {isLoading ? getText('Yuborilmoqda...', 'Отправка...', 'Submitting...') : getText('Yuborish', 'Отправить', 'Submit')}
                    </button>
                    {isError && <p className="error">{getText('Sharh yuborishda xatolik yuz berdi.', 'Произошла ошибка при отправке отзыва.', 'An error occurred while submitting the review.')}</p>}
                    {message && <p className="message">{message}</p>}
                </form>
            </div>
        </div>
    );
}