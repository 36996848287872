import React, { useContext, useState } from 'react';
import './SearchResults.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import arrow from '../../img/direction-left 01.svg';
import { Contexts } from '../../context/Contexts';

export default function SearchResults() {
    const {
        truncateLength,
        languageEng,
        languageRu,
        languageUz,
        navHeight,
        EngData,
        RusData,
        UzbData,
    } = useContext(Contexts);
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const query = queryParams.get('query');
    const lowercaseQuery = query && query.toLowerCase();

    const filterResults = (data) => data?.filter(element => element?.modelName?.toLowerCase().includes(lowercaseQuery));
    const resultEng = filterResults(EngData);
    const resultRus = filterResults(RusData);
    const resultUzb = filterResults(UzbData);

    const [currentPage, setCurrentPage] = useState(1);
    const productsPerPage = 20;
    const totalPages = Math.ceil(resultEng?.length / productsPerPage);
    const startIndex = (currentPage - 1) * productsPerPage;
    const endIndex = startIndex + productsPerPage;

    const getCurrentProducts = (results) => results?.slice(startIndex, endIndex);
    const currentProductsEng = getCurrentProducts(resultEng);
    const currentProductsRus = getCurrentProducts(resultRus);
    const currentProductsUzb = getCurrentProducts(resultUzb);

    const goToPage = (page) => setCurrentPage(page);
    const isDisabled = (type) => type === 'prev' ? currentPage === 1 : currentPage === totalPages;

    const renderProducts = (products) => products?.map(element => (
        <div className="col-3 col-xs-6" key={element.id}>
            <div className="box">
                <div className="carouselImg">
                    <img src={element.filePath} alt={element.modelName} />
                </div>
                <div className="text">
                    <p onClick={() => navigate(`/productInfo/${element.id}`)}>
                        <span>
                            {element?.modelName?.length > truncateLength ? `${element.modelName.slice(0, truncateLength)}...` : element?.modelName}
                        </span>
                    </p>
                    <p><span>{element?.brandName}</span></p>
                    <p><span>{element?.produced}</span></p>
                </div>
            </div>
        </div>
    ));

    const renderPagination = () => (
        <div className="btnGroup flex">
            <div className={`btn prev ${isDisabled('prev') ? 'disabled' : ''}`} onClick={() => goToPage(currentPage - 1)}>
                <ReactSVG src={arrow} className='mySvg' />
            </div>
            {[...Array(totalPages).keys()].map((page) => {
                if (totalPages <= 7 || page === 0 || page === totalPages - 1 || (page >= currentPage - 2 && page <= currentPage + 2)) {
                    return (
                        <div
                            className={`btn ${currentPage === page + 1 ? 'active' : ''}`}
                            key={page}
                            onClick={() => goToPage(page + 1)}
                        >
                            {page + 1}
                        </div>
                    );
                }
                if (page === currentPage - 3 || page === currentPage + 3) {
                    return <div className="btn" key={page}>...</div>;
                }
                return null;
            })}
            <div className={`btn next ${isDisabled('next') ? 'disabled' : ''}`} onClick={() => goToPage(currentPage + 1)}>
                <ReactSVG src={arrow} className='mySvg' />
            </div>
        </div>
    );

    return (
        <div className='searchResults navPositionFixed' style={{ marginTop: `${navHeight}px` }}>
            <div className="container">
                <div className="pageTop">
                    <Link to={'/'}>
                        {languageEng ? 'Home' : languageRu ? 'Главная страница' : 'Bosh sahifa'}
                    </Link>
                    <ReactSVG src={arrow} className='mySvg' />
                    <Link to={'/productsPage'}>
                        {languageEng ? 'Products' : languageRu ? 'Продукты' : 'Mahsulotlar'}
                    </Link>
                    <ReactSVG src={arrow} className='mySvg' />
                    <p>
                        {languageEng ? 'Found products' : languageRu ? 'Найденные продукты' : 'Topilgan mahsulotlar'}
                    </p>
                </div>
                <div className="row productsBox">
                    {languageEng ? renderProducts(currentProductsEng) :
                        languageRu ? renderProducts(currentProductsRus) :
                            languageUz && renderProducts(currentProductsUzb)
                    }
                </div>
                {resultEng?.length > 0 && renderPagination()}
            </div>
        </div>
    );
}