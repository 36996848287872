import React, { useContext, useState } from 'react';
import './ChangeNews.css';
import { Contexts } from '../../context/Contexts';
import { useUpdateNewEngMutation } from '../../redux/NewsEngApi';
import { useUpdateNewRusMutation } from '../../redux/NewsRusApi';
import { useUpdateNewUzbMutation } from '../../redux/NewsUzbApi';

export default function ChangeNews() {
  const {
    newsEngData,
    newsRusData,
    newsUzbData,
    setActiveNewsCoponent
  } = useContext(Contexts);

  const [updateNewEng, resultEng] = useUpdateNewEngMutation();
  const [updateNewRus, resultRus] = useUpdateNewRusMutation();
  const [updateNewUzb, resultUzb] = useUpdateNewUzbMutation();

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState('eng');
  const [newsItem, setNewsItem] = useState(null);

  const handleSearch = () => {
    let newsData = [];
    if (selectedLanguage === 'eng') newsData = newsEngData;
    else if (selectedLanguage === 'rus') newsData = newsRusData;
    else if (selectedLanguage === 'uzb') newsData = newsUzbData;

    const foundNews = newsData.find(news => news.title.toLowerCase().includes(searchTerm.toLowerCase()));
    setNewsItem(foundNews);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (!newsItem) return;

    if (newsItem?.title?.length <= 0 || newsItem?.description?.length <= 0) {
      alert('Please fill in all required fields.');
      return;
    }

    try {
      const news = {
        id: newsItem?.id,
        title: newsItem?.title,
        description: newsItem?.description
      };

      if (selectedLanguage === 'eng') {
        await updateNewEng(news).unwrap();
        alert('News is Changed');
        setActiveNewsCoponent('createNewsComponent');
      } else if (selectedLanguage === 'rus') {
        await updateNewRus(news).unwrap();
        alert('News is Changed');
        setActiveNewsCoponent('createNewsComponent');
      } else if (selectedLanguage === 'uzb') {
        await updateNewUzb(news).unwrap();
        alert('News is Changed');
        setActiveNewsCoponent('createNewsComponent');
      }
    } catch (error) {
      console.error('Error updating news:', error);
      alert('Error updating news: ' + error.data.errors);
    }
  };

  console.log(resultRus);

  return (
    <div className='changeNews'>
      <div className='searchSection'>
        <div className='languageButtons'>
          <button className={selectedLanguage === 'eng' ? 'active' : ''} onClick={() => setSelectedLanguage('eng')}>English</button>
          <button className={selectedLanguage === 'rus' ? 'active' : ''} onClick={() => setSelectedLanguage('rus')}>Russian</button>
          <button className={selectedLanguage === 'uzb' ? 'active' : ''} onClick={() => setSelectedLanguage('uzb')}>Uzbek</button>
        </div>
        <div className="flex">
          <input
            type='text'
            placeholder='Search'
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button onClick={handleSearch}>Search</button>
        </div>
      </div>
      {newsItem && (
        <div className='newsForm'>
          <label>Заголовок на {selectedLanguage === 'eng' ? 'английском' : selectedLanguage === 'rus' ? 'русском' : 'узбекском'}</label>
          <input
            type='text'
            value={newsItem.title}
            onChange={(e) => setNewsItem({ ...newsItem, title: e.target.value })}
          />
          <label>Описание на {selectedLanguage === 'eng' ? 'английском' : selectedLanguage === 'rus' ? 'русском' : 'узбекском'}</label>
          <textarea
            value={newsItem.description}
            onChange={(e) => setNewsItem({ ...newsItem, description: e.target.value })}
          ></textarea>
          <button onClick={handleUpdate}>Обновить</button>
        </div>
      )}
    </div>
  );
}