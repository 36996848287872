import React, { useContext } from 'react';
import './FirstReview.css';
import img from '../../img/Group 47225.svg';
import { ReactSVG } from 'react-svg';
import { Contexts } from '../../context/Contexts';

export default function FirstReview() {
    const { languageUz, languageRu, languageEng, setActiveComponent } = useContext(Contexts);

    const getText = (uzText, ruText, engText) => {
        if (languageUz) return uzText;
        if (languageRu) return ruText;
        if (languageEng) return engText;
    };

    return (
        <div className='firstReview'>
            <h3>{getText('Sharhlar', 'Отзывы', 'Reviews')}</h3>
            <ReactSVG src={img} />
            <h4>{getText('Mahsulot haqida sharh qoldiring', 'Оставьте отзыв о товаре первым', 'Leave the first review about the product')}</h4>
            <button onClick={() => setActiveComponent('reviews')}>
                {getText('Sharh qoldirish', 'Оставить отзыв', 'Leave a review')}
            </button>
        </div>
    );
}