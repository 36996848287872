import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import './AboutUs.css';
import rasm from '../../img/photo_2024-05-29_11-31-09.jpg';
import { Contexts } from '../../context/Contexts';

export default function AboutUs() {
    const { languageRu, languageUz, languageEng } = useContext(Contexts);

    const getText = (uzText, engText, ruText) => languageUz ? uzText : languageEng ? engText : ruText;

    const aboutText = getText(
        `2021 yilda «Vet Tex Shifo» MCHJ tashkil etildi. Biz veterinariya preparatlari va texnikalarini yetkazib berish hamda ulardan foydalanish bo'yicha o'qitish bilan shug'ullanamiz. Faoliyatimiz davomida 200 dan ortiq mijozlarga xizmat ko'rsatdik va veterinariya klinikalari va xususiy veterinarlarga sifatli yechimlar taklif qildik.
        <br /><br />
        Mamnuniyat bilan aytamizki, bizda har doim zamonaviy veterinariya texnologiyalarini tanlash va joriy etishda yordam beruvchi ekspertlar jamoasi mavjud. Bizning maqsadimiz - mijozlarimizni eng yaxshi mahsulotlar va xizmatlar bilan ta'minlash, shunday qilib ular o'z bemorlari uchun samarali g'amxo'rlik ko'rsata olishadi.
        <br /><br />
        «Vet Tex Shifo» kompaniyasida biz yuqori sifat standartlariga amal qilamiz va xizmatlarimizni doimo yaxshilashga intilamiz. Ishonamizki, faoliyatimiz veterinariya sohasining rivojlanishiga hissa qo'shadi va uy hayvonlarining hayotini sog'lom va baxtli qiladi.`,
        `In 2021, LLC "Vet Tech Shifo" was founded. We specialize in the supply and training of veterinary medicines and equipment. Since our inception, we have served over 200 clients, providing quality solutions for veterinary clinics and private veterinarians.
        <br /><br />
        We take pride in our team of experts who are always ready to assist in selecting and implementing the latest veterinary technologies. Our goal is to provide our clients with the best products and services so that they can effectively care for their patients.
        <br /><br />
        At "Vet Tech Shifo," we adhere to high standards of quality and constantly strive to improve our services. We believe that our work contributes to the development of the veterinary field and helps make the lives of pets healthier and happier.`,
        `2021 Основано ООО «Вет Тех Шифо». Мы занимаемся поставкой и обучением по использованию ветеринарных препаратов и техники. За время нашей работы мы обслужили более 200 клиентов, предоставляя качественные решения для ветеринарных клиник и частных ветеринаров.
        <br /><br />
        Мы гордимся нашей командой экспертов, которые всегда готовы помочь с выбором и внедрением самых современных технологий в ветеринарии. Наша цель – обеспечить наших клиентов лучшими продуктами и сервисом, чтобы они могли эффективно заботиться о своих пациентах.
        <br /><br />
        В «Вет Тех Шифо» мы придерживаемся высоких стандартов качества и стремимся постоянно улучшать наши услуги. Мы верим, что наша работа способствует развитию ветеринарной отрасли и помогает сделать жизнь домашних животных здоровее и счастливее.`
    );

    return (
        <div className='aboutUs'>
            <div className="container">
                <div className="top">
                    <h3>{getText('Biz haqimizda', 'About us', 'О нас')}</h3>
                    <Link to={'/about'}>{getText('Barchasini ko`rish', 'See all', 'Посмотреть все')}</Link>
                </div>
                <div className="bottom">
                    <div className="row">
                        <div className="col-7 col-xs-12">
                            <div className="img">
                                <img src={rasm} alt="About us" />
                            </div>
                        </div>
                        <div className="col-5 col-xs-12">
                            <div className="text">
                                <p dangerouslySetInnerHTML={{ __html: aboutText }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}