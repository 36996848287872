import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseQueryWithAuth = fetchBaseQuery({
    baseUrl: "http://45.138.158.239:5002/api/",
    prepareHeaders: (headers) => {
        const token = localStorage.getItem('token');
        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        }
        headers.set('Content-Type', 'application/json');
        return headers;
    },
    responseHandler: async (response) => {
        if (!response.ok) {
            const error = await response.text();
            throw new Error(error || response.statusText);
        }
        const contentType = response.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
            return response.json();
        } else {
            return response.text();
        }
    },
});

export const productEngApi = createApi({
    reducerPath: 'productEngApi',
    baseQuery: baseQueryWithAuth,
    tagTypes: ['Product'],
    endpoints: (builder) => ({
        createProductEng: builder.mutation({
            query: (product) => ({
                url: 'ProductEng/CreatProductEng',
                method: 'POST',
                body: product,
            }),
            invalidatesTags: [{ type: 'Product', id: 'LIST' }],
        }),
        updateProductEng: builder.mutation({
            query: ({ id, ...product }) => ({
                url: `ProductEng/UpdateProductEng?id=${id}`,
                method: 'PUT',
                body: product,
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'Product', id }],
        }),
        getProductEngById: builder.query({
            query: (id) => `ProductEng/GetProductEng?id=${id}`,
            providesTags: (result, error, id) => [{ type: 'Product', id }],
        }),
        getAllProductsEng: builder.query({
            query: ({ pageSize, pageIndex }) =>
                `ProductEng/GetAllProductEng?pageSize=${pageSize}&pageIndex=${pageIndex}`,
            providesTags: (result) =>
                result ?
                    [...result.map(({ id }) => ({ type: 'Product', id })), { type: 'Product', id: 'LIST' }]
                    : [{ type: 'Product', id: 'LIST' }],
        }),
        getAllProductsEngByBrandName: builder.query({
            query: ({ pageSize, pageIndex, brandName }) =>
                `ProductEng/GetAllProductByBrandNameEng?pageSize=${pageSize}&pageIndex=${pageIndex}&brandName=${brandName}`,
            providesTags: (result) =>
                result ?
                    [...result.map(({ id }) => ({ type: 'Product', id })), { type: 'Product', id: 'LIST' }]
                    : [{ type: 'Product', id: 'LIST' }],
        }),
        deleteProductEng: builder.mutation({
            query: (id) => ({
                url: `ProductEng/DeleteProductAll?id=${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, id) => [{ type: 'Product', id }, { type: 'Product', id: 'LIST' }],
        }),
    }),
});

export const {
    useCreateProductEngMutation,
    useUpdateProductEngMutation,
    useGetProductEngByIdQuery,
    useGetAllProductsEngQuery,
    useGetAllProductsEngByBrandNameQuery,
    useDeleteProductEngMutation,
} = productEngApi;