import React, { useContext, useState } from 'react';
import './ClientForm.css';
import callCenter from '../../img/callCenter.svg';
import { Contexts } from '../../context/Contexts';
import { useCreateOrderMutation } from '../../redux/OrderApi';

export default function ClientForm() {

    const [createOrder, { isLoading, isSuccess, isError }] = useCreateOrderMutation();

    const {
        languageUz,
        languageRu,
        languageEng,
        customerName,
        setCustomerName,
        customerNumber,
        setCustomerNumber,
    } = useContext(Contexts);

    const [productModelName, setProductModelName] = useState('');
    const [errorState, setErrorState] = useState('');

    const handleSubmitCallMe = async (e) => {
        e.preventDefault();
        if (customerName === '' || customerNumber === '' || productModelName === '') {
            setErrorState(
                languageEng ? 'All fields are required.' :
                    languageRu ? 'Все поля обязательны для заполнения.' :
                        languageUz && 'Barcha maydonlar majburiy.'
            );
            return;
        }
        setErrorState('');
        try {
            await createOrder({ customerName, customerNumber, productModelName }).unwrap();
            alert(
                languageEng ? 'Order sent successfully!' :
                    languageRu ? 'Заказ успешно отправлен!' :
                        languageUz && 'Buyurtma muvaffaqiyatli yuborildi!'
            );
            setCustomerName('');
            setCustomerNumber('');
            setProductModelName('');
        } catch (error) {
            alert(
                languageEng ? 'Failed to send order.' :
                    languageRu ? 'Не удалось отправить заказ.' :
                        languageUz && 'Buyurtmani yuborib bo`lmadi.'
            );
        }
    };

    const getText = (uzText, engText, ruText) => languageUz ? uzText : languageEng ? engText : ruText;

    return (
        <div className='clientForm'>
            <div className="container">
                <div className="row">
                    <div className="col-6 col-md-12 col-xs-12">
                        <div className="box">
                            <h2>{getText('Batafsil ma\'lumot oling', 'Get more info', 'Получите больше информации')}</h2>
                            <form onSubmit={handleSubmitCallMe}>
                                <label htmlFor="customerName">{getText('Ismingiz', 'First name', 'Имя')}</label>
                                <input
                                    type="text"
                                    id="customerName"
                                    name="customerName"
                                    placeholder='Write...'
                                    value={customerName}
                                    onChange={(e) => setCustomerName(e.target.value)}
                                    required
                                />
                                <label htmlFor="customerNumber">{getText('Telefon raqamingiz', 'Phone number', 'Номер телефона')}</label>
                                <input
                                    className='typeNumber'
                                    type="text"
                                    id="customerNumber"
                                    name="customerNumber"
                                    placeholder='Write...'
                                    value={customerNumber}
                                    onChange={(e) => setCustomerNumber(e.target.value)}
                                    required
                                />
                                <label htmlFor="productModelName">{getText('Izoh qoldirish', 'Comment', 'Комментировать')}</label>
                                <textarea
                                    id="productModelName"
                                    name="productModelName"
                                    placeholder='Write...'
                                    value={productModelName}
                                    onChange={(e) => setProductModelName(e.target.value)}
                                    required
                                />
                                {errorState && <p className="errorState">{errorState}</p>}
                                <button type="submit" disabled={isLoading}>
                                    {getText('Jo`natish', 'Send', 'Отправить')}
                                </button>
                            </form>
                            {isLoading && <p>{getText('Yuborilmoqda...', 'Sending...', 'Отправка...')}</p>}
                            {isSuccess && <p>{getText('Buyurtma muvaffaqiyatli yuborildi!', 'Order sent successfully!', 'Заказ успешно отправлен!')}</p>}
                            {isError && <p>{getText('Buyurtmani yuborib bo`lmadi.', 'Failed to send order.', 'Не удалось отправить заказ.')}</p>}
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="img">
                            <img src={callCenter} alt="Call Center" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}