import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useContext } from 'react';
import { Contexts } from '../../context/Contexts';
import { useGetProductUzbByIdQuery } from '../../redux/ProductUzbApi';
import { useCreateOrderMutation } from '../../redux/OrderApi';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    padding: '36px 48px',
    outline: 'none',
    borderRadius: '8px',
    width: '440px'
};

const inputStyle = {
    border: '1px solid rgba(203, 213, 225, 1)',
    outline: 'none',
    padding: '16px 12px',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    borderRadius: '8px',
    width: '100%',
    marginTop: '6px',
};

const labelStyle = {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    marginBottom: '6px',
};

const boxStyle = {
    marginBottom: '16px',
};

const borderStyle = {
    padding: '16px 12px',
    borderRadius: '8px',
    background: 'rgba(237, 237, 237, 1)',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
};

const buttonStyle = {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '24px',
    width: '100%',
    background: 'rgba(0, 172, 179, 1)',
    borderRadius: '8px',
    border: 'none',
    color: 'white',
    padding: '12px',
};

export default function OrdersModal({ modelName }) {
    
    const [createOrder] = useCreateOrderMutation();

    const {
        ordersModal,
        setOrdersModal,
        languageUz,
        languageRu,
        languageEng,
        customerName,
        setCustomerName,
        customerNumber,
        setCustomerNumber,
    } = useContext(Contexts);

    const handleClose = () => setOrdersModal(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (customerName === '' || customerNumber === '') {
            alert(
                getText(
                    'Barcha maydonlar majburiy.',
                    'All fields are required.',
                    'Все поля обязательны для заполнения.'
                )
            );
            return;
        }
        try {
            await createOrder({ customerName, customerNumber, productModelName: modelName }).unwrap();
            alert(
                getText(
                    'Buyurtma muvaffaqiyatli yuborildi!',
                    'Order sent successfully!',
                    'Заказ успешно отправлен!'
                )
            );
            setCustomerName('');
            setCustomerNumber('');
            setOrdersModal(false);
        } catch (error) {
            alert(
                getText(
                    'Buyurtmani yuborib bo`lmadi.',
                    'Failed to send order.',
                    'Не удалось отправить заказ.'
                )
            );
        }
    };

    const getText = (uzText, engText, ruText) => languageUz ? uzText : languageEng ? engText : ruText;

    return (
        <Modal open={ordersModal} onClose={handleClose}>
            <Box sx={style}>
                <form onSubmit={handleSubmit}>
                    <div style={boxStyle} className="box">
                        <label style={labelStyle} htmlFor="clientName">
                            {getText('Ism Familyangiz', 'Full Name', 'Имя Фамилия')}
                        </label>
                        <input
                            id='clientName'
                            value={customerName}
                            style={inputStyle}
                            type="text"
                            onChange={(e) => setCustomerName(e.target.value)}
                        />
                    </div>
                    <div style={boxStyle} className="box">
                        <label style={labelStyle} htmlFor="clientNumber">
                            {getText('Telefon raqamingiz', 'Phone Number', 'Номер телефона')}
                        </label>
                        <input
                            id='clientNumber'
                            value={customerNumber}
                            style={inputStyle}
                            type="text"
                            onChange={(e) => setCustomerNumber(e.target.value)}
                        />
                    </div>
                    <div style={boxStyle} className="box">
                        <p style={labelStyle}>{getText('Maxsulot modeli', 'Model Name', 'Модель продукта')}</p>
                        <p style={borderStyle}>{modelName}</p>
                    </div>
                    <button style={buttonStyle} type='submit'>
                        {getText('Buyurtma qilish', 'Place Order', 'Оформить заказ')}
                    </button>
                </form>
            </Box>
        </Modal>
    );
}