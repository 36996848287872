import React, { useContext } from 'react'
import { Contexts } from '../../context/Contexts'
import { Navigate, Outlet } from 'react-router-dom'

export default function ProtectedRoutes() {
    const { isLogin } = useContext(Contexts)
    return (
        isLogin ? <Outlet /> : <Navigate to={'login'} />
    )
}
