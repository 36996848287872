import React, { useContext } from 'react';
import './Delivery.css';
import car from '../../img/image 83.png';
import { Contexts } from '../../context/Contexts';

const Delivery = ({ paymentContract, paymentType }) => {
    const { languageUz, languageRu, languageEng } = useContext(Contexts);

    const getTitle = () => {
        if (languageEng) return 'Delivery and payment';
        if (languageRu) return 'Доставка и оплата';
        if (languageUz) return 'Yetkazib berish va to`lash';
    };

    const getSubtitle = () => {
        if (languageEng) {
            return 'The terms of the contract and delivery time are determined individually with each client. Medical equipment is delivered throughout the territory of the Republic of Uzbekistan. If the product is in stock, it will be shipped within 48 hours.';
        }
        if (languageRu) {
            return 'Условия договора и срок поставки определяются индивидуально с каждым клиентом. Медицинское оборудование доставляется по всей территории Республики Узбекистан. Если товар есть в наличии, он будет отправлен в течение 48 часов.';
        }
        if (languageUz) {
            return 'Shartnoma shartlari va yetkazib berish muddati har bir mijoz bilan alohida kelishilgan holda belgilanadi. Tibbiy asbob-uskunalar O`zbekiston Respublikasi bo`ylab yetkazib beriladi. Agar tovarlar zaxirada bo`lsa jo`natish 48 soat ichida amalga oshiriladi.';
        }
    };

    const getPaymentTitle = () => {
        if (languageEng) return 'Payment type';
        if (languageRu) return 'Тип оплаты';
        if (languageUz) return 'To`lov turi';
    };

    return (
        <div className='delivery'>
            <div className='container'>
                <div className='row'>
                    <div className='col-6 col-xs-12'>
                        <h4 className='title'>{getTitle()}</h4>
                        <p className='subTitle'>{getSubtitle()}</p>
                        <div className='img flex'>
                            <img src={car} alt='Delivery car' />
                        </div>
                    </div>
                    <div className='col-6 col-xs-12'>
                        <h4 className='title'>{getPaymentTitle()}</h4>
                        <p className='subTitle'>{paymentType}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Delivery;