export const setAuthToken = (token) => ({
    type: 'SET_AUTH_TOKEN',
    payload: token,
});

const initialState = {
    token: null,
};

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_AUTH_TOKEN':
            return {
                ...state,
                token: action.payload,
            };
        default:
            return state;
    }
};
