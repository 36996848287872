import React, { useContext, useEffect, useState } from 'react';
import './AddProductEng.css';
import { useCreateProductEngMutation } from '../../redux/ProductEngApi';
import { Contexts } from '../../context/Contexts';

export default function AddProductEng() {
    const [createProductEng, createProductEngResult] = useCreateProductEngMutation();
    const { setActiveProductComponent, modelName, setModelName, EngData } = useContext(Contexts);

    const [formData, setFormData] = useState({
        brandName: '',
        description: '',
        guaranteePeriod: '',
        madeIn: '',
        produced: '',
        paymentType: '',
        extraDevices: '',
        paymentContract: false,
        service: false,
    });
    const [dataInfo, setDataInfo] = useState('');
    const [noFreePlease, setNoFreePlease] = useState('');
    const [redBorder, setRedBorder] = useState(false);

    const filterName = EngData?.some((dataName) => dataName.modelName === modelName);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const { brandName, description, guaranteePeriod, madeIn, produced, paymentType, extraDevices } = formData;

        if ([modelName, brandName, description, guaranteePeriod, madeIn, produced, paymentType, extraDevices].some((field) => field === '')) {
            setNoFreePlease("Please don't leave it empty!");
            return;
        }

        if (filterName) {
            setRedBorder(true);
            setDataInfo('The name of such a model is in the database.');
            return;
        }

        try {
            const productEng = {
                modelName,
                ...formData,
            };

            await createProductEng(productEng);
            setDataInfo('Product data has been uploaded.');
            setActiveProductComponent('addProductRus');
        } catch (error) {
            console.error('Error adding product:', error);
            setDataInfo('Error adding product. Please try again.');
        }
    };

    useEffect(() => {
        if (createProductEngResult?.isLoading) {
            setDataInfo('Loading data.');
        } else if (createProductEngResult?.isSuccess) {
            setFormData({
                brandName: '',
                description: '',
                guaranteePeriod: '',
                madeIn: '',
                produced: '',
                paymentType: '',
                extraDevices: '',
                paymentContract: false,
                service: false,
            });
            setDataInfo('Data has been loaded.');
        } else if (createProductEngResult?.isError) {
            setDataInfo('Error, please try again.');
        }
    }, [createProductEngResult?.isError, createProductEngResult?.isLoading, createProductEngResult?.isSuccess]);

    return (
        <div className='addProductEng'>
            <form onSubmit={handleSubmit}>
                <div className="formGroup flex">
                    <label htmlFor="modelName">Model name</label>
                    <input
                        className={redBorder ? 'active' : ''}
                        placeholder='Please write in English.'
                        type="text"
                        id="modelName"
                        value={modelName}
                        onChange={(e) => setModelName(e.target.value)}
                    />
                </div>
                <div className="formGroup flex">
                    <label htmlFor="brandName">Brand name</label>
                    <input
                        placeholder='Please write in English.'
                        type="text"
                        id="brandName"
                        name="brandName"
                        value={formData.brandName}
                        onChange={handleChange}
                    />
                </div>
                <div className="formGroup flex">
                    <label htmlFor="produced">Produced</label>
                    <input
                        placeholder='Please write in English.'
                        type="text"
                        id="produced"
                        name="produced"
                        value={formData.produced}
                        onChange={handleChange}
                    />
                </div>
                <div className="formGroup flex">
                    <label htmlFor="paymentType">Payment type</label>
                    <input
                        placeholder='Please write in English.'
                        type="text"
                        id="paymentType"
                        name="paymentType"
                        value={formData.paymentType}
                        onChange={handleChange}
                    />
                </div>
                <div className="formGroup flex">
                    <label htmlFor="extraDevices">Extra devices</label>
                    <input
                        placeholder='Please write in English.'
                        type="text"
                        id="extraDevices"
                        name="extraDevices"
                        value={formData.extraDevices}
                        onChange={handleChange}
                    />
                </div>
                <div className="formGroup flex">
                    <label htmlFor="description">Description</label>
                    <textarea
                        placeholder='Please write in English.'
                        type="text"
                        id="description"
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                    ></textarea>
                </div>
                <div className="formGroup flex">
                    <label htmlFor="guaranteePeriod">Guarantee period</label>
                    <input
                        placeholder='Please write in English.'
                        type="text"
                        id="guaranteePeriod"
                        name="guaranteePeriod"
                        value={formData.guaranteePeriod}
                        onChange={handleChange}
                    />
                </div>
                <div className="formGroup flex">
                    <label htmlFor="madeIn">Made in</label>
                    <input
                        placeholder='Please write in English.'
                        type="text"
                        id="madeIn"
                        name="madeIn"
                        value={formData.madeIn}
                        onChange={handleChange}
                    />
                </div>
                <div className="formGroup flex checkbox-wrapper">
                    <label htmlFor="paymentContract">Payment contract</label>
                    <label className="rocker rocker-small">
                        <input
                            type="checkbox"
                            id="paymentContract"
                            name="paymentContract"
                            checked={formData.paymentContract}
                            onChange={handleChange}
                        />
                        <span className="switch-left">Yes</span>
                        <span className="switch-right">No</span>
                    </label>
                </div>
                <div className="formGroup flex checkbox-wrapper">
                    <label htmlFor="service">Service</label>
                    <label className="rocker rocker-small">
                        <input
                            type="checkbox"
                            id="service"
                            name="service"
                            checked={formData.service}
                            onChange={handleChange}
                        />
                        <span className="switch-left">Yes</span>
                        <span className="switch-right">No</span>
                    </label>
                </div>
                <h4 className='dataInfo'>{noFreePlease}</h4>
                <h4 className='dataInfo'>{dataInfo}</h4>
                <button type="submit">Next</button>
            </form>
        </div>
    );
}