import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseQuery = fetchBaseQuery({
  baseUrl: 'http://45.138.158.239:5002/',
  prepareHeaders: (headers) => {
    const token = localStorage.getItem('token');
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

export const fileForNewsApi = createApi({
  reducerPath: 'fileForNewsApi',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    addAttachmentForNews: builder.mutation({
      query: (formData) => ({
        url: '/api/File/AddAttachmentForNews',
        method: 'POST',
        body: formData,
      }),
    }),
  }),
});

export const { useAddAttachmentForNewsMutation } = fileForNewsApi;