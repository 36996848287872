import React, { useContext, useState, useEffect, useRef } from 'react';
import './Navbar.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ExpandMore } from '@mui/icons-material';
import logo from '../../img/logo.png';
import { menu, menuEng, menuRu } from '../../utilits/constants';
import search from '../../img/search.svg';
import katalog from '../../img/katalog.svg';
import { Contexts } from '../../context/Contexts';
import MenuIcon from '@mui/icons-material/Menu';
import Ru from '../../img/russia (4) 1.svg';
import Uz from '../../img/uzbekistan (6) 1.svg';
import Eng from '../../img/Flags.svg';

export default function Navbar() {
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();
    const {
        openSearchModal,
        setOpenSearchModal,
        languageRu,
        setLanguageRu,
        languageUz,
        setLanguageUz,
        languageEng,
        setLanguageEng,
        isLogin,
        setIsLogin,
        navHeight,
        setNavHeight,
    } = useContext(Contexts);

    const menuRef = useRef(null);
    const burgerMenuRef = useRef(null);
    const navRef = useRef(null);
    const [languageModal, setLanguageModal] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        function handleClickOutside(event) {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsOpen(false);
                if (burgerMenuRef.current) {
                    burgerMenuRef.current.style.maxHeight = '0';
                }
            }
        }

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (navRef.current) {
            setNavHeight(navRef.current.offsetHeight);
        }
    }, [navRef.current?.offsetHeight]);

    const handleToggleMenu = () => {
        setIsOpen(!isOpen);
        if (burgerMenuRef.current) {
            burgerMenuRef.current.style.maxHeight = isOpen ? '0' : '500px';
        }
    };

    const handleClose = () => {
        if (burgerMenuRef.current) {
            burgerMenuRef.current.style.maxHeight = '0';
        }
    };

    const handleToggleModal = () => {
        setOpenSearchModal(!openSearchModal);
    };

    const handleLanguage = () => {
        setLanguageModal(!languageModal);
    };

    const setLanguage = (langRu, langUz, langEng) => {
        localStorage.setItem('languageRu', langRu);
        localStorage.setItem('languageUz', langUz);
        localStorage.setItem('languageEng', langEng);
        setLanguageRu(langRu);
        setLanguageUz(langUz);
        setLanguageEng(langEng);
        setLanguageModal(false);
    };

    const handleLogOut = () => {
        sessionStorage.setItem('isLogin', false);
        setIsLogin(JSON.parse(sessionStorage.getItem('isLogin')));
        navigate('/');
    };

    const getText = (uzText, engText, ruText) => languageUz ? uzText : languageEng ? engText : ruText;

    const renderMenu = (menuList) => (
        menuList.map((element, index) => (
            <li key={index} className={location.pathname === element.slug ? 'active' : ''}>
                <Link onClick={handleClose} to={element.slug}>{element.linkName}</Link>
            </li>
        ))
    );

    return (
        <nav className='nav navFixed' ref={navRef}>
            {/* <div className="testMode flex">
                {[...Array(10)].map((_, index) => (
                    <h3 key={index}>{getText('Sayt sinov holatida.', 'Site in test mode.', 'Сайт в тестовом режиме.')}</h3>
                ))}
            </div> */}
            <div className="top">
                <div className="container">
                    <div className="row navTop">
                        <div className="col-6 col-xs-6">
                            <div className="number">
                                <Link to='tel:+998977312394'>+998 97 731 23 94</Link>
                            </div>
                        </div>
                        <div className="col-6 col-xs-6">
                            <div className="language flex">
                                <div
                                    className="languageBox flex"
                                    onClick={handleLanguage}
                                >
                                    <p>{getText('Til o`zgartirish', 'Language', 'Изменить язык')}</p>
                                    <ExpandMore />
                                </div>
                                {languageModal && (
                                    <>
                                        <div className="overlay" onClick={() => setLanguageModal(false)}></div>
                                        <div className="selectLanguageAll">
                                            <div className="triangle"></div>
                                            <div className="selectLanguage flex">
                                                <div className="selectLanguageBox rus flex"
                                                    onClick={() => setLanguage(true, false, false)}
                                                >
                                                    <img src={Ru} alt="" />
                                                    <p>Русский</p>
                                                </div>
                                                <div className="selectLanguageBox uzb flex"
                                                    onClick={() => setLanguage(false, true, false)}
                                                >
                                                    <img src={Uz} alt="" />
                                                    <p>O`zbekcha</p>
                                                </div>
                                                <div className="selectLanguageBox eng flex"
                                                    onClick={() => setLanguage(false, false, true)}
                                                >
                                                    <img src={Eng} alt="" />
                                                    <p>English</p>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bottom">
                <div className="container">
                    <div className="row navBottom">
                        <div className="col-3 col-md-6 col-xs-8">
                            <div className="logo">
                                <Link to='/'>
                                    <img src={logo} alt="Logo" />
                                </Link>
                            </div>
                        </div>
                        <div className="col-6">
                            <ul className='navMenu'>
                                {languageUz && renderMenu(menu)}
                                {languageRu && renderMenu(menuRu)}
                                {languageEng && renderMenu(menuEng)}
                                {isLogin && (
                                    <li className={location.pathname === '/thisPAGEisOnLyFoRaDmInS' ? 'active' : ''}>
                                        <Link to={'/thisPAGEisOnLyFoRaDmInS'}>{getText('Admin', 'Admin', 'Админ')}</Link>
                                    </li>
                                )}
                            </ul>
                        </div>
                        <div className="col-3 col-md-6 col-xs-4">
                            <div className="btnGroup">
                                <div className='searchBtn' onClick={handleToggleModal}>
                                    <img src={search} alt="Search" />
                                </div>
                                <div
                                    className='catalog'
                                    onClick={isLogin ? handleLogOut : () => navigate('/thisPAGEisOnLyFoRaDmInS')}
                                >
                                    <img src={katalog} alt="Catalog" />
                                    <p>{!isLogin ? getText('Kirish', 'Login', 'Логин') : getText('Chiqish', 'Logout', 'Выход')}</p>
                                </div>
                                <div
                                    className="burgerMenu"
                                    ref={menuRef}
                                >
                                    <MenuIcon onClick={handleToggleMenu} />
                                    <ul ref={burgerMenuRef} className='navBurgerMenu'>
                                        {languageUz && renderMenu(menu)}
                                        {languageRu && renderMenu(menuRu)}
                                        {languageEng && renderMenu(menuEng)}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
}