import { configureStore } from "@reduxjs/toolkit";
import { productEngApi } from "./ProductEngApi";
import { productRusApi } from "./ProductRusApi";
import { productUzbApi } from "./ProductUzbApi";
import { adminApi } from "./AdminApi";
import { newsEngApi } from './NewsEngApi'
import { newsRusApi } from './NewsRusApi'
import { newsUzbApi } from './NewsUzbApi'
import { authApi } from "./AuthApi";
import { setupListeners } from "@reduxjs/toolkit/query";
import { authReducer } from "./auth";
import { fileForProductsApi } from "./FileForProducts";
import { orderApi } from "./OrderApi";
import { fileForNewsApi } from "./FileForNews";
import commentsApi from "./CommentsApi";

export const store = configureStore({
    reducer: {
        [productEngApi.reducerPath]: productEngApi.reducer,
        [productRusApi.reducerPath]: productRusApi.reducer,
        [productUzbApi.reducerPath]: productUzbApi.reducer,
        [adminApi.reducerPath]: adminApi.reducer,
        [orderApi.reducerPath]: orderApi.reducer,
        [newsEngApi.reducerPath]: newsEngApi.reducer,
        [newsRusApi.reducerPath]: newsRusApi.reducer,
        [newsUzbApi.reducerPath]: newsUzbApi.reducer,
        [authApi.reducerPath]: authApi.reducer,
        auth: authReducer,
        [fileForProductsApi.reducerPath]: fileForProductsApi.reducer,
        [fileForNewsApi.reducerPath]: fileForNewsApi.reducer,
        [commentsApi.reducerPath]: commentsApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat([
            productEngApi.middleware,
            productRusApi.middleware,
            productUzbApi.middleware,
            adminApi.middleware,
            orderApi.middleware,
            newsEngApi.middleware,
            newsRusApi.middleware,
            newsUzbApi.middleware,
            authApi.middleware,
            fileForProductsApi.middleware,
            fileForNewsApi.middleware,
            commentsApi.middleware,
        ])
});

setupListeners(store.dispatch)