import React, { useContext } from 'react'
import './NewsComponent.css'
import CreateNews from '../CreateNews/CreateNews'
import DeleteNews from '../DeleteNews/DeleteNews'
import ChangeNews from '../ChangeNews/ChangeNews'
import { Contexts } from '../../context/Contexts'

export default function NewsComponent() {

  const {activeNewsComponent, setActiveNewsCoponent} = useContext(Contexts)

  const handleCreateNewsComponentClick = () => {
    setActiveNewsCoponent('createNewsComponent')
  }
  const handleUpdateNewsComponent = () => {
    setActiveNewsCoponent('changeNewsComponent')
  }
  const handleDeleteNewsComponentClick = () => {
    setActiveNewsCoponent('deleteNewsComponent')
  }

  return (
    <div className='newsComponent'>
      <div className="top flex">
        <div
          className={`btn ${activeNewsComponent === 'createNewsComponent' ? 'active' : ''}`}
          onClick={handleCreateNewsComponentClick}
        >
          <p>Create News</p>
        </div>
        <div
          className={`btn ${activeNewsComponent === 'changeNewsComponent' ? 'active' : ''}`}
          onClick={handleUpdateNewsComponent}
        >
          <p>Change News</p>
        </div>
        <div
          className={`btn ${activeNewsComponent === 'deleteNewsComponent' ? 'active' : ''}`}
          onClick={handleDeleteNewsComponentClick}
        >
          <p>Delete News</p>
        </div>
      </div>
      <div className="bottom">
        {activeNewsComponent === 'createNewsComponent' && <CreateNews />}
        {activeNewsComponent === 'changeNewsComponent' && <ChangeNews />}
        {activeNewsComponent === 'deleteNewsComponent' && <DeleteNews />}
      </div>
    </div>
  )
}
