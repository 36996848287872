import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { ReactSVG } from 'react-svg'
import arrow from '../../img/direction-left 01.svg'
import './Contact.css'
import rasm from '../../img/image 80.png'
import { Contexts } from '../../context/Contexts'
import MapComponent from '../../components/MapComponent/MapComponent'

export default function Contact() {
    const { isOpenMap, setIsOpenMap, languageEng, languageRu, languageUz, navHeight } = useContext(Contexts)

    const handleOpenMap = (e) => {
        e.preventDefault()
        setIsOpenMap(!isOpenMap)
    }

    return (
        <div className='contact navPositionFixed' style={{ marginTop: `${navHeight}px` }}>
            <div className="container">
                <div className="pageTop">
                    <Link to={'/'}>
                        {languageUz ? 'Bosh sahifa' :
                            languageEng ? 'Home' :
                                languageRu && 'Главная страница'
                        }
                    </Link>
                    <ReactSVG src={arrow} className='mySvg' />
                    <p>
                        {languageUz ? 'Aloqa' :
                            languageEng ? 'Contacts' :
                                languageRu && 'Контакты'
                        }
                    </p>
                </div>
                <div className="contactBox">
                    <h3>
                        {languageEng ? 'Address' :
                            languageRu ? 'Адрес' :
                                languageUz && 'Manzil'
                        }
                    </h3>
                    <div className="box flex">
                        <div className="img flex">
                            {!isOpenMap ? <img src={rasm} alt="" /> : <MapComponent />}
                        </div>
                        <div className="text">
                            <Link to='tel:+998977312394' className="number">+998 97 731 23 94</Link>
                            <Link to='tel:+998994779423' className="number">+998 99 477 94 23</Link>
                            {languageEng ?
                                <p className="address">
                                    Uzbekistan
                                    <br />
                                    Yangiyer city, Sirdarya region
                                    <br />
                                    Shukrona neighborhood, Amir Temur street 98
                                </p> : languageUz ?
                                    <p className="address">
                                        O`zbekiston
                                        <br />
                                        Sirdaryo viloyati, Yangiyer shahri
                                        <br />
                                        Shukrona maxallasi, Amir Temur ko`chasi 98-uy
                                    </p> : languageRu &&
                                    <p className="address">
                                        Узбекистан
                                        <br />
                                        г. Янгиер Сырдарьинской области.
                                        <br />
                                        махалля Шукрона, улица Амира Темура 98
                                    </p>
                            }
                            <p className="btn" onClick={(e) => handleOpenMap(e)}>
                                {languageEng ? 'Location' :
                                    languageRu ? 'Местонахождение' :
                                        languageUz && 'Joylashuv'
                                }
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
