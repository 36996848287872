import React, { useContext, useEffect, useRef, useState } from 'react';
import './About.css';
import arrow from '../../img/direction-left 01.svg';
import { ReactSVG } from 'react-svg';
import { Link } from 'react-router-dom';
import rasm1 from '../../img/Rectangle 1098.png';
import rasm2 from '../../img/Rectangle 1099.png';
import rasm3 from '../../img/Rectangle 1100.png';
import rasm4 from '../../img/Rectangle 1101.png';
import rasm5 from '../../img/Rectangle 1102.png';
import rasm6 from '../../img/Rectangle 1103.png';
import rasm7 from '../../img/Rectangle 1104.png';
import ras1 from '../../img/1671793601_kartinkin-net-p-motivatsiya-personala-kartinki-pinterest-41.jpg';
import ras2 from '../../img/1671793656_kartinkin-net-p-motivatsiya-personala-kartinki-pinterest-15.jpg';
import ras3 from '../../img/Rectangle 13.png';
import ellipse from '../../img/Ellipse 662.png';
import ellipse2 from '../../img/Ellipse 662-1.png';
import ellipse3 from '../../img/Ellipse 662-2.png';
import ell1 from '../../img/Ellipse 663.png';
import ell2 from '../../img/Ellipse 664.png';
import ell3 from '../../img/Ellipse 665.png';
import ell5 from '../../img/Ellipse 667.png';
import { Contexts } from '../../context/Contexts';

export default function About() {
    const { languageEng, languageRu, languageUz, navHeight } = useContext(Contexts);
    const [textHeightOne, setTextHeightOne] = useState(0);
    const [textHeightTwo, setTextHeightTwo] = useState(0);

    const textRefOne = useRef();
    const textRefTwo = useRef();

    useEffect(() => {
        if (textRefOne.current) {
            setTextHeightOne(textRefOne.current.offsetHeight);
        }
        if (textRefTwo.current) {
            setTextHeightTwo(textRefTwo.current.offsetHeight);
        }
    }, [textRefOne.current?.offsetHeight, textRefTwo.current?.offsetHeight]);

    const getText = (uzText, engText, ruText) => languageUz ? uzText : languageEng ? engText : ruText;

    return (
        <div className='about navPositionFixed' style={{ marginTop: `${navHeight}px` }}>
            <div className="container">
                <div className="pageTop">
                    <Link to={'/'}>
                        {getText('Bosh sahifa', 'Home', 'Главная страница')}
                    </Link>
                    <ReactSVG src={arrow} className='mySvg' />
                    <p>{getText('Biz haqimizda', 'About Us', 'О нас')}</p>
                </div>
                <div className="aboutGif flex">
                    <iframe
                        width="100%"
                        height="450"
                        src="https://www.youtube.com/embed/tn22HdBu00o?si=H53QNWXh2sdmOE5M"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen></iframe>
                </div>
                {/* <div className="ourClients">
                    <h4>{getText('Bizning mijozlarimiz', 'Our clients', 'Наши клиенты')}</h4>
                    <div className="imgBox flex">
                        {[rasm1, rasm2, rasm3, rasm4, rasm5, rasm6, rasm7].map((src, index) => (
                            <div className="img flex" key={index}>
                                <img src={src} alt="" />
                            </div>
                        ))}
                    </div>
                </div> */}
                <div className="achievements">
                    <h4>{getText('Bizning yutuqlar', 'Our achievements', 'Наши достижения')}</h4>
                    <div className="achievementsBox flex">
                        <div className="left flex">
                            <div className="circular"></div>
                            <div className="border" style={{ height: `${textHeightOne}px` }}></div>
                            <div className="circular"></div>
                            <div className="border" style={{ height: `${textHeightTwo}px` }}></div>
                            <div className="circular"></div>
                        </div>
                        <div className="right">
                            <div ref={textRefOne} className="box flex">
                                <div className="text">
                                    <h4>2021</h4>
                                    <p>{getText(
                                        '2021 yilda «Vet Tex Shifo» MCHJ tashkil etildi va faoliyatimiz boshlangan kundan boshlab veterinariya klinikalari va xususiy veterinarlar uchun ishonchli hamkor sifatida o`zimizni ko`rsatdik. Mijozlarimizga zamonaviy veterinariya texnologiyalaridan samarali foydalanish uchun zarur bilimlarni berish maqsadida sifatli o`qitish tizimini ishlab chiqdik va joriy etdik. Ilk yilda 200 dan ortiq mijozlarga yuqori sifatli preparatlar va uskunalarni taqdim etdik.',
                                        'In 2021, LLC "Vet Tech Shifo" was founded, and from the very beginning, we established ourselves as a reliable partner for veterinary clinics and private veterinarians. We developed and implemented a quality training system for our clients, equipping them with the necessary knowledge to effectively use advanced veterinary technologies. In our first year, we successfully served over 200 clients, providing them with high-quality medicines and equipment.',
                                        'В 2021 году ООО «Вет Тех Шифо» было основано, и с первых дней нашей деятельности мы смогли зарекомендовать себя как надежный партнер для ветеринарных клиник и частных ветеринаров. Мы разработали и внедрили систему качественного обучения для наших клиентов, обеспечив их необходимыми знаниями для эффективного использования передовых ветеринарных технологий. За первый год работы мы успешно обслужили более 200 клиентов, предоставив им высококачественные препараты и оборудование.'
                                    )}</p>
                                </div>
                                <div className="img flex">
                                    <img src={ras1} alt="" />
                                </div>
                            </div>
                            <div ref={textRefTwo} className="box flex">
                                <div className="text">
                                    <h4>2022</h4>
                                    <p>{getText(
                                        '2022 yilda biz mahsulotlar assortimentimizni kengaytirib, dunyoning yetakchi ishlab chiqaruvchilaridan eng yangi veterinariya preparatlari va uskunalarini qo`shdik. Mijozlarimiz uchun muntazam ravishda seminarlar va vebinarlar o`tkazishni boshladik, ularning veterinariya sohasidagi so`nggi yutuqlardan xabardor bo`lishlarini ta\'minlash maqsadida. Natijada, mijozlarimiz o`z bemorlariga ko`rsatilayotgan parvarish sifatini sezilarli darajada yaxshilashga erishdilar.',
                                        'In 2022, we expanded our product range by adding the latest veterinary medicines and equipment from leading global manufacturers. We also began conducting regular seminars and webinars for our clients to keep them informed about the latest advancements in veterinary medicine. As a result, our clients were able to significantly improve the quality of care for their patients.',
                                        'В 2022 году мы расширили наш ассортимент продукции, добавив новейшие ветеринарные препараты и оборудование от ведущих мировых производителей. Мы также начали проведение регулярных семинаров и вебинаров для наших клиентов, чтобы они могли оставаться в курсе последних достижений в области ветеринарии. В результате наши клиенты смогли значительно улучшить качество ухода за своими пациентами.'
                                    )}</p>
                                </div>
                                <div className="img flex">
                                    <img src={ras2} alt="" />
                                </div>
                            </div>
                            <div className="box flex">
                                <div className="text">
                                    <h4>2023</h4>
                                    <p>{getText(
                                        '2023 yilda biz yirik veterinariya klinikalari va xo`jaliklari uchun individual yechimlar ishlab chiqishga ixtisoslashgan yangi bo`limni ochdik. Shu tufayli mijozlarimizga ularning aniq ehtiyojlariga moslashtirilgan yanada moslashuvchan yechimlarni taklif qilish imkoniyatiga ega bo`ldik. Shuningdek, doimiy mijozlarimiz uchun qo`shimcha bonuslar va chegirmalarni berishni nazarda tutuvchi mijozlar sadoqati dasturini ishga tushirdik.',
                                        'In 2023, we launched a new division specializing in developing customized solutions for large veterinary clinics and farms. This enabled us to offer more flexible and tailored solutions to meet the specific needs of our clients. Additionally, we introduced a loyalty program for our regular clients, allowing them to receive additional bonuses and discounts.',
                                        'В 2023 году мы открыли новое подразделение, специализирующееся на разработке индивидуальных решений для крупных ветеринарных клиник и хозяйств. Благодаря этому, мы смогли предложить более гибкие и адаптированные к конкретным потребностям наших клиентов решения. Кроме того, мы запустили программу лояльности для наших постоянных клиентов, что позволило им получать дополнительные бонусы и скидки.'
                                    )}</p>
                                </div>
                                <div className="img flex">
                                    <img src={ras3} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="happyClients">
                    <h4>{getText('Hizmatimizdan mamnun haridorlar', 'Clients who are satisfied with our service', 'Клиенты, которые удовлетворены нашим сервисом')}</h4>
                    <div className="clientsBox flex">
                        {[
                            {
                                text: getText(
                                    'Men veterinariya sohasida 10 yildan ortiq vaqtdan beri ishlayman va «Vet Tex Shifo» MCHJ bilan hamkorlik qilish mening ishimni ancha yengillatdi. Ular taqdim etayotgan uskuna va preparatlar doimo yuqori sifatli bo`ladi, o`qitish esa bizga eng yangi davolash usullaridan xabardor bo`lib turishga yordam beradi. Men ularning xizmatiga juda mamnunman va ularni barcha hamkasblarimga tavsiya qilaman.',
                                    'I have been working as a veterinarian for over 10 years, and partnering with LLC "Vet Tech Shifo" has significantly eased my work. Their equipment and medicines are always of high quality, and their training helps us stay updated on the latest treatment methods. I am very satisfied with their service and recommend them to all my colleagues.',
                                    'Я работаю ветеринаром уже более 10 лет, и сотрудничество с ООО «Вет Тех Шифо» значительно облегчило мою работу. Их оборудование и препараты всегда высокого качества, а обучение помогает нам оставаться в курсе новейших методов лечения. Я очень доволен их сервисом и рекомендую их всем коллегам.'
                                ),
                                name: 'Akmal Parpiyev',
                                img: ellipse
                            },
                            {
                                text: getText(
                                    "Bizning klinika doimiy ravishda «Vet Tex Shifo» MCHJdan uskunalar sotib oladi. Biz ularning professional yondashuvi va mayda-chuydaga e'tibor berishlarini qadrlaymiz. Ularning mahsulotlari tufayli biz bemorlarimizga ko`rsatilayotgan xizmat sifatini ancha yaxshiladik. Bizga muntazam ravishda vebinarlar o`tkazib turishlari uchun alohida rahmat.",
                                    'Our clinic regularly purchases equipment from LLC "Vet Tech Shifo". We appreciate their professional approach and attention to detail. Thanks to their products, we have significantly improved the quality of care for our patients. Special thanks for the regular webinars that help our team stay on top.',
                                    'Наша клиника постоянно приобретает оборудование у ООО «Вет Тех Шифо». Мы ценим их профессиональный подход и внимание к деталям. Благодаря их продукции, мы смогли значительно улучшить качество обслуживания наших пациентов. Отдельное спасибо за регулярные вебинары, которые помогают нашей команде оставаться на высоте.'
                                ),
                                name: 'Nilufar Hamidova',
                                img: ellipse2
                            },
                            {
                                text: getText(
                                    'Xususiy veterinariya amaliyotining egasi sifatida men doimo mijozlarim uchun eng yaxshi yechimlarni izlayman. «Vet Tex Shifo» MCHJ nafaqat sifatli preparatlar va uskunalar, balki ajoyib xizmatni ham taqdim etadi. Ular doimo yordam berishga va har qanday savollarga javob berishga tayyor. Men hamkorlikdan juda mamnunman va ular bilan ishlashni davom ettirishni rejalashtirmoqdaman.',
                                    'As a private veterinary practice owner, I am always looking for the best solutions for my clients. LLC "Vet Tech Shifo" provides not only quality medicines and equipment but also excellent service. They are always ready to help and answer any questions. I am very satisfied with the cooperation and plan to continue working with them.',
                                    'Как владелец частной ветеринарной практики, я всегда ищу лучшие решения для своих клиентов. ООО «Вет Тех Шифо» предоставляет не только качественные препараты и оборудование, но и отличный сервис. Они всегда готовы помочь и ответить на любые вопросы. Я очень доволен сотрудничеством и планирую продолжать работать с ними.'
                                ),
                                name: 'Doston Akramov',
                                img: ellipse3
                            }
                        ].map((client, index) => (
                            <div className="box" key={index}>
                                <p>{client.text}</p>
                                <div className="imgBox flex">
                                    <div className="img flex">
                                        <img src={client.img} alt="" />
                                    </div>
                                    <p>{client.name}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {/* <div className="ourTeam">
                    <h4>{getText('Bizning jamoa', 'Our team', 'Наша команда')}</h4>
                    <div className="teamBox row">
                        {[
                            { name: 'Gulzira Azizova', img: ell1 },
                            { name: 'Munira Asalova', img: ell2 },
                            { name: 'Akmal Parpiyev', img: ell3 },
                            { name: 'Latofat Sharipova', img: ell5 }
                        ].map((member, index) => (
                            <div className="box col-xs-6" key={index}>
                                <div className="img">
                                    <img src={member.img} alt="" />
                                </div>
                                <p>{member.name}</p>
                            </div>
                        ))}
                    </div>
                </div> */}
            </div>
        </div>
    );
}