import React, { useContext, useState } from 'react';
import './AdminPage.css';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import CreateIcon from '@mui/icons-material/Create';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AssessmentIcon from '@mui/icons-material/Assessment';
import FeedIcon from '@mui/icons-material/Feed';
import AdminComponent from '../../components/AdminComponent/AdminComponent';
import AddProduct from '../../components/AddProduct/AddProduct';
import UpdateProduct from '../../components/UpdateProduct/UpdateProduct';
import DeleteProduct from '../../components/DeleteProduct/DeleteProduct';
import OrdersComponent from '../../components/OrdersComponent/OrdersComponent';
import NewsComponent from '../../components/NewsComponent/NewsComponent';
import { Contexts } from '../../context/Contexts';

export default function AdminPage() {
    const [activeComponent, setActiveComponent] = useState('adminComponent');
    const { navHeight } = useContext(Contexts);

    const components = [
        { name: 'adminComponent', label: 'Admin', icon: <ManageAccountsIcon />, component: <AdminComponent /> },
        { name: 'createComponent', label: 'Create', icon: <NoteAddIcon />, component: <AddProduct /> },
        { name: 'updateComponent', label: 'Update', icon: <CreateIcon />, component: <UpdateProduct /> },
        { name: 'deleteComponent', label: 'Delete', icon: <DeleteForeverIcon />, component: <DeleteProduct /> },
        { name: 'ordersComponent', label: 'Orders', icon: <AssessmentIcon />, component: <OrdersComponent /> },
        { name: 'newsComponent', label: 'News', icon: <FeedIcon />, component: <NewsComponent /> }
    ];

    return (
        <div className='adminPage navPositionFixed' style={{ marginTop: `${navHeight}px` }}>
            <div className="container">
                <div className="adminPageBody row">
                    <div className="col-3">
                        <div className="left">
                            {components.map(({ name, label, icon }) => (
                                <div
                                    key={name}
                                    className={`box flex ${activeComponent === name ? 'active' : ''}`}
                                    onClick={() => setActiveComponent(name)}
                                >
                                    {icon}
                                    <p>{label}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-9">
                        <div className="right">
                            {components.find(({ name }) => name === activeComponent)?.component}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}