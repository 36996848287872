import React, { useContext } from 'react'
import './Ads.css'
import car from '../../img/image 63.png'
import settings from '../../img/image 63-1.png'
import man from '../../img/image 63-2.png'
import shield from '../../img/image 63-3.png'
import { Contexts } from '../../context/Contexts'

export default function Ads() {

    const { languageRu, languageUz, languageEng, } = useContext(Contexts)

    return (
        <div className='ads'>
            <div className="container">
                <div className="row">
                    {languageUz ?
                        <>
                            <div className="col-3 col-xs-12">
                                <div className="box">
                                    <img src={car} alt="" />
                                    <h4>Tez yetkazib berish</h4>
                                </div>
                            </div>
                            <div className="col-3 col-xs-12">
                                <div className="box">
                                    <img src={settings} alt="" />
                                    <h4>Bepul o`rnatib berish</h4>
                                </div>
                            </div>
                            <div className="col-3 col-xs-12">
                                <div className="box">
                                    <img src={man} alt="" />
                                    <h4>Bepul service ko`rsatish</h4>
                                </div>
                            </div>
                            <div className="col-3 col-xs-12">
                                <div className="box">
                                    <img src={shield} alt="" />
                                    <h4>Uzoq muddatli kafolot</h4>
                                </div>
                            </div>
                        </> : languageEng ?
                            <>
                                <div className="col-3 col-xs-12">
                                    <div className="box">
                                        <img src={car} alt="" />
                                        <h4>Fast delivery</h4>
                                    </div>
                                </div>
                                <div className="col-3 col-xs-12">
                                    <div className="box">
                                        <img src={settings} alt="" />
                                        <h4>Free installation</h4>
                                    </div>
                                </div>
                                <div className="col-3 col-xs-12">
                                    <div className="box">
                                        <img src={man} alt="" />
                                        <h4>Free service</h4>
                                    </div>
                                </div>
                                <div className="col-3 col-xs-12">
                                    <div className="box">
                                        <img src={shield} alt="" />
                                        <h4>Long term warranty</h4>
                                    </div>
                                </div>
                            </> : languageRu &&
                            <>
                                <div className="col-3 col-xs-12">
                                    <div className="box">
                                        <img src={car} alt="" />
                                        <h4>Быстрая доставка</h4>
                                    </div>
                                </div>
                                <div className="col-3 col-xs-12">
                                    <div className="box">
                                        <img src={settings} alt="" />
                                        <h4>Бесплатная установка</h4>
                                    </div>
                                </div>
                                <div className="col-3 col-xs-12">
                                    <div className="box">
                                        <img src={man} alt="" />
                                        <h4>Бесплатное обслуживание</h4>
                                    </div>
                                </div>
                                <div className="col-3 col-xs-12">
                                    <div className="box">
                                        <img src={shield} alt="" />
                                        <h4>Долгосрочная гарантия</h4>
                                    </div>
                                </div>
                            </>
                    }
                </div>
            </div>
        </div>
    )
}
