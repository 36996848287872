import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Carousel1 from '../../img/image 4.png'
import Carousel2 from '../../img/18505753.jpeg'
import Carousel3 from '../../img/51d845c9.jpeg'
import './Carousel.css'

export default function Carousel() {
  const options = {
    loop: true,
    responsive: {
      0: {
        items: 1
      }
    },
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true
  };
  return (
    <div className='carousel'>
      <OwlCarousel {...options}>
        <div className="carouselImg">
          <img src={Carousel1} alt="" />
        </div>
        <div className="carouselImg">
          <img src={Carousel2} alt="" />
        </div>
        <div className="carouselImg">
          <img src={Carousel3} alt="" />
        </div>
      </OwlCarousel>
    </div>
  )
}