import React, { useContext, useState } from 'react';
import './AddNewsFile.css';
import { useAddAttachmentForNewsMutation } from '../../redux/FileForNews';
import Loading from '../Loading/Loading';
import { Contexts } from '../../context/Contexts';

export default function AddNewsFile() {

    const [addAttachmentForNews, { isLoading, isError }] = useAddAttachmentForNewsMutation();

    const [file, setFile] = useState(null);
    const { setNewsFileOpen } = useContext(Contexts)

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (file) {
            const formData = new FormData();
            formData.append('request', file);

            try {
                await addAttachmentForNews(formData);
                alert('Files uploaded');
                setFile(null)
                setNewsFileOpen(false)
            } catch (error) {
                alert('Error:', error);
            }
        }
    };

    return (
        <div className='addNewsFile'>
            <form onSubmit={handleSubmit}>
                <input type="file" onChange={handleFileChange} />
                <button type='submit'>Отправить файл</button>
            </form>
            {isLoading && <Loading />}
            {isError && <p>Произошла ошибка при отправке файла.</p>}
        </div>
    );
}