import React, { useContext } from 'react'
import Carousel from '../../components/Carousel/Carousel'
import TopFour from '../../components/Top-4/TopFour'
import Ads from '../../components/ADs/Ads'
import AboutUs from '../../components/AboutUs/AboutUs'
import ClientForm from '../../components/ClientForm/ClientForm'
import Products from '../../components/Products/Products'
import { Contexts } from '../../context/Contexts'

export default function Home() {

  const { navHeight } = useContext(Contexts)

  return (
    <div className='home navPositionFixed' style={{ marginTop: `${navHeight}px` }}>
      <Carousel />
      <TopFour />
      <Ads />
      <AboutUs />
      <Products />
      <ClientForm />
    </div>
  )
}
