import React, { useContext } from 'react'
import { Contexts } from '../../context/Contexts'

export default function MapComponent() {
    const { isOpenMap } = useContext(Contexts)
    return (
        isOpenMap && (
            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d723.363161976851!2d68.82836726961605!3d40.27417699821644!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDDCsDE2JzI3LjAiTiA2OMKwNDknNDQuNCJF!5e1!3m2!1sru!2s!4v1716245261928!5m2!1sru!2s"
                title='Sirdayro'
                width="510"
                height="248"
                style={{ border: 0, borderRadius: '8px' }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
        )
    )
}
