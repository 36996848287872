import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const commentsApi = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({ baseUrl: 'http://45.138.158.239:5002/api' }),
    tagTypes: ['Comment'],
    endpoints: (builder) => ({
        createComment: builder.mutation({
            query: (newComment) => ({
                url: '/Comment/CreatComment',
                method: 'POST',
                body: newComment,
            }),
            invalidatesTags: ['Comment'],
        }),
        getComment: builder.query({
            query: (id) => `/Comment/GetComment?id=${id}`,
            providesTags: (result, error, id) => [{ type: 'Comment', id }],
        }),
        getAllComments: builder.query({
            query: ({ pageSize, pageIndex }) =>
                `/Comment/GetAllComments?pageSize=${pageSize}&pageIndex=${pageIndex}`,
            providesTags: (result) =>
                result
                    ? [...result.map(({ id }) => ({ type: 'Comment', id })), { type: 'Comment', id: 'LIST' }]
                    : [{ type: 'Comment', id: 'LIST' }],
        }),
        deleteComment: builder.mutation({
            query: (id) => ({
                url: `/Comment/DeleteComment?id=${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, id) => [{ type: 'Comment', id }],
        }),
    }),
});

export const {
    useCreateCommentMutation,
    useGetCommentQuery,
    useGetAllCommentsQuery,
    useDeleteCommentMutation,
} = commentsApi;

export default commentsApi;