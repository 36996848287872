import React, { useState } from 'react';
import './DeleteAdminComponent.css';
import { useDeleteAdminMutation } from '../../redux/AdminApi';

export default function DeleteAdminComponent({ admins }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [deleteAdmin, { isLoading, isError, error }] = useDeleteAdminMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const adminToDelete = admins.find(admin => admin.email === email); // admin.password === password
      if (!adminToDelete) {
        console.error('Администратор не найден.');
        return;
      }
      await deleteAdmin(adminToDelete?.id);
      alert(`Admin ${adminToDelete?.email} is deleted.`)
      setEmail('');
      setPassword('');
    } catch (error) {
      console.error('Ошибка удаления администратора:', error);
    }
  };


  return (
    <div className='deleteAdminComponent'>
      <form onSubmit={handleSubmit}>
        <div className="formGroup flex">
          <label htmlFor="email">Email</label>
          <input
            type="text"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="formGroup flex">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button type="submit" disabled={isLoading}>
          {isLoading ? 'Deleting...' : 'Delete admin'}
        </button>
        {isError && <div>Ошибка при удалении администратора.</div>}
      </form>
    </div>
  );
}