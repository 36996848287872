import React, { useContext, useEffect } from 'react'
import './NewsInfo.css'
import arrow from '../../img/direction-left 01.svg'
import { ReactSVG } from 'react-svg'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Contexts } from '../../context/Contexts'
import { useGetNewEngByIdQuery } from '../../redux/NewsEngApi'
import { useGetNewUzbByIdQuery } from '../../redux/NewsUzbApi'
import { useGetNewRusByIdQuery } from '../../redux/NewsRusApi'
import { format, parseISO } from 'date-fns'

export default function NewsInfo() {

    const { id } = useParams()

    const { data: newsUzb, isLoading: isLoadingUzb } = useGetNewUzbByIdQuery(id)
    const { data: newsEng, isLoading: isLoadingEng } = useGetNewEngByIdQuery(id)
    const { data: newsRus, isLoading: isLoadingRus } = useGetNewRusByIdQuery(id)

    const {
        navHeight,
        languageUz,
        languageRu,
        languageEng,
        setOpenLoading,
        newsEngData,
        newsUzbData,
        newsRusData,
    } = useContext(Contexts)

    const newsData = languageEng ? newsEngData :
        languageRu ? newsRusData :
            newsUzbData

    const filteredNewsData = newsData?.filter((news) => news.id !== JSON.parse(id))

    const navigate = useNavigate()

    useEffect(() => {
        if ((languageEng && isLoadingEng) || (languageRu && isLoadingRus) || (languageUz && isLoadingUzb)) {
            setOpenLoading(true)
        } else {
            setOpenLoading(false)
        }
    }, [isLoadingEng, isLoadingRus, isLoadingUzb, languageEng, languageRu, languageUz, setOpenLoading])

    const renderNewsInfo = (news) => {
        if (!news) return null;

        const formattedDate = news.createAt ? format(parseISO(news.createAt), 'dd.MM.yyyy') : '';

        return (
            <div className="newsInfoBox">
                <div className="img">
                    <img src={news.attachments?.filePath} alt="" />
                </div>
                <div className="text">
                    <p className="addedTime">
                        {formattedDate}
                    </p>
                    <h2 className='title'>{news.title}</h2>
                    <p className="desc">
                        {news.description}
                    </p>
                </div>
            </div>
        );
    }

    const renderNewsAll = (newsAll) => {
        if (!newsAll) return null;

        const newsAllDate = newsAll.createAt ? format(parseISO(newsAll.createAt), 'dd.MM.yyyy') : '';

        return (
            <div className="allBox flex" key={newsAll.id}>
                <div className="text flex">
                    <h2 onClick={() => navigate(`/newsInfo/${newsAll.id}`)} className="allTitle">{newsAll.title}</h2>
                    <p className="time">{newsAllDate}</p>
                </div>
                <div onClick={() => navigate(`/newsInfo/${newsAll.id}`)} className="img">
                    <img src={newsAll.attachments?.filePath} alt="" />
                </div>
            </div>
        )
    }

    return (
        <div className='newsInfo navPositionFixed' style={{ marginTop: `${navHeight}px` }}>
            <div className="container">
                <div className="pageTop">
                    <Link to={'/'}>
                        {
                            languageEng ? 'Home' :
                                languageUz ? 'Bosh sahifa' :
                                    languageRu && 'Главная страница'
                        }
                    </Link>
                    <ReactSVG src={arrow} className='mySvg' />
                    <Link to={'/news'}>
                        {
                            languageEng ? 'News' :
                                languageUz ? 'Yangiliklar' :
                                    languageRu && 'Новости'
                        }
                    </Link>
                    <ReactSVG src={arrow} className='mySvg' />
                    {
                        languageEng ?
                            <p>
                                {newsEng?.title?.length > 20 ?
                                    newsEng?.title?.slice(0, 20) + '...' :
                                    newsEng?.title
                                }
                            </p> :
                            languageUz ?
                                <p>
                                    {newsUzb?.title?.length > 20 ?
                                        newsUzb?.title?.slice(0, 20) + '...' :
                                        newsUzb?.title
                                    }
                                </p> :
                                languageRu && <p>
                                    {newsRus?.title?.length > 20 ?
                                        newsRus?.title?.slice(0, 20) + '...' :
                                        newsRus?.title
                                    }
                                </p>
                    }
                </div>
                <div className="row">
                    <div className="col-8 col-xs-12">
                        <div className="right">
                            {languageUz ? renderNewsInfo(newsUzb) :
                                languageEng ? renderNewsInfo(newsEng) :
                                    languageRu && renderNewsInfo(newsRus)}
                        </div>
                    </div>
                    {filteredNewsData?.length > 0 &&
                        <div className="col-4 col-xs-12">
                            <div className="left">
                                {filteredNewsData?.map(renderNewsAll)}
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}