import React, { useContext, useEffect, useState } from 'react';
import './LoginModal.css';
import { Contexts } from '../../context/Contexts';
import { useNavigate } from 'react-router-dom';
import { useLoginMutation } from '../../redux/AuthApi';
import { setAuthToken } from '../../redux/auth';
import { useDispatch } from 'react-redux';
import Loading from '../Loading/Loading';

export default function LoginModal() {
    const [login, { error, isLoading, isError }] = useLoginMutation();
    const navigate = useNavigate();
    const { setIsLogin, setOpenLoading, navHeight, languageUz, languageRu, languageEng } = useContext(Contexts);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const dispatch = useDispatch();

    const getText = (uzText, ruText, engText) => {
        if (languageUz) return uzText;
        if (languageRu) return ruText;
        if (languageEng) return engText;
    };

    const handleSubmitLogin = async (e) => {
        e.preventDefault();
        try {
            const credentials = { email, password };
            const result = await login(credentials).unwrap();
            const token = result.data;
            localStorage.setItem('token', token);
            sessionStorage.setItem('isLogin', true);
            setIsLogin(JSON.parse(sessionStorage.getItem('isLogin')));
            dispatch(setAuthToken(token));
            navigate('/thisPAGEisOnLyFoRaDmInS');
        } catch (error) {
            console.error('Authentication error:', error);
        }
    };

    useEffect(() => {
        setOpenLoading(isLoading);
    }, [isLoading, setOpenLoading]);

    return (
        <div className='loginModal navPositionFixed' style={{ marginTop: `${navHeight}px` }}>
            <form className='loginForm' onSubmit={handleSubmitLogin}>
                <div className='formGroup'>
                    <label htmlFor='email'>{getText('Email', 'Электронная почта', 'Email')}</label>
                    <input
                        type='text'
                        id='email'
                        name='email'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <div className='formGroup'>
                    <label htmlFor='password'>{getText('Parol', 'Пароль', 'Password')}</label>
                    <input
                        type='password'
                        id='password'
                        name='password'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                <button type='submit' disabled={isLoading}>
                    {getText('Kirish', 'Войти', 'Login')}
                </button>
                {isError && <div>{error?.data?.data || getText('Kirishda xatolik yuz berdi', 'Произошла ошибка при входе', 'An error occurred during login')}</div>}
            </form>
        </div>
    );
}