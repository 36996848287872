import React, { useContext, useRef, useState } from 'react'
import './Footer.css'
import logo from '../../img/footer-logo.svg'
import { Link } from 'react-router-dom'
import paynet from '../../img/image 55.png'
import oson from '../../img/image 57.png'
import click from '../../img/image 50.png'
import payme from '../../img/image 49.png'
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import TelegramIcon from '@mui/icons-material/Telegram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Contexts } from '../../context/Contexts'

const Address = ({ languageUz, languageRu, languageEng }) => {
    if (languageEng) {
        return (
            <p className="address">
                Uzbekistan
                <br />
                Yangiyer city, Sirdarya region
                <br />
                Shukrona neighborhood, Amir Temur street 98
            </p>
        )
    }
    if (languageUz) {
        return (
            <p className="address">
                O`zbekiston
                <br />
                Sirdaryo viloyati, Yangiyer shahri
                <br />
                Shukrona maxallasi, Amir Temur ko`chasi 98-uy
            </p>
        )
    }
    if (languageRu) {
        return (
            <p className="address">
                Узбекистан
                <br />
                г. Янгиер Сырдарьинской области
                <br />
                махалля Шукрона, улица Амира Темура 98
            </p>
        )
    }
    return null;
}

const FooterLink = ({ to, text }) => (
    <li>
        <Link to={to}>{text}</Link>
    </li>
);

const SocialLink = ({ to, Icon, label, className }) => (
    <div className={`btn ${className}`}>
        <Link className='flex' to={to} target='_blank'>
            <div className="icon flex">
                <Icon />
            </div>
            <p>{label}</p>
        </Link>
    </div>
);

const Footer = () => {

    const { languageUz, languageRu, languageEng } = useContext(Contexts);
    const [bottomHeight, setBottomHeight] = useState(0)
    const topRef = useRef(null)
    const bottomRef = useRef(null)

    const companyLinks = [
        { to: '/', text: languageUz ? 'Bosh sahifa' : languageEng ? 'Home' : 'Главная страница' },
        { to: '/news', text: languageUz ? 'Yangiliklar' : languageEng ? 'News' : 'Новости' },
        { to: '/about', text: languageUz ? 'Biz haqimizda' : languageEng ? 'About Us' : 'О нас' },
        { to: '/productsPage', text: languageUz ? 'Mahsulotlar' : languageEng ? 'Products' : 'Продукты' },
        { to: '/contact', text: languageUz ? 'Aloqa' : languageEng ? 'Contact Us' : 'Контакты' },
    ];

    const socialLinks = [
        { to: 'https://www.instagram.com/rustam.abdurayimov.33?igsh=b2hwa2czeTBoZjF0', Icon: InstagramIcon, label: 'Instagram', className: 'instagram' },
        { to: 'https://www.facebook.com/rustam.abdurayimov.33?mibextid=kFxxJD', Icon: FacebookIcon, label: 'Facebook', className: 'facebook' },
        { to: 'https://www.t.me/texnovet', Icon: TelegramIcon, label: 'Telegram', className: 'telegram' },
        { to: 'https://youtube.com/@texnovet?si=gzrgL3WIY7n859n8', Icon: YouTubeIcon, label: 'YouTube', className: 'youtube' },
    ];

    return (
        <footer className='footer'>
            <div className="top">
                <div className="container">
                    <div className="row">
                        <div className="col-4 col-xs-12">
                            <div className="logo">
                                <div className="img">
                                    <Link to={'/'}>
                                        <img src={logo} alt="logo" />
                                    </Link>
                                </div>
                                <Address languageUz={languageUz} languageRu={languageRu} languageEng={languageEng} />
                                <Link to={'tel:+998994779423'}>+998-99-477-94-23</Link>
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="box">
                                <p>
                                    {languageUz ? 'Bizning kompaniya' : languageEng ? 'Our company' : 'Наша компания'}
                                </p>
                                <ul>
                                    {companyLinks.map((link, index) => (
                                        <FooterLink key={index} to={link.to} text={link.text} />
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="btnBox">
                                {socialLinks.map((link, index) => (
                                    <SocialLink key={index} to={link.to} Icon={link.Icon} label={link.label} className={link.className} />
                                ))}
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="imageGroup">
                                {[paynet, oson, click, payme].map((src, index) => (
                                    <div className="img" key={index}>
                                        <img src={src} alt={`payment-${index}`} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bottom">
                <div className="container">
                    <p>All rights reserved! @ 2024 Vet tex shifo</p>
                    <div className="btnGroup">
                        {socialLinks.map((link, index) => (
                            <a key={index} href={link.to} target='_blank' rel='noreferrer'>
                                <link.Icon />
                            </a>
                        ))}
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;