import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQueryWithAuth = fetchBaseQuery({
    baseUrl: "http://45.138.158.239:5002/",
    prepareHeaders: (headers) => {
        const token = localStorage.getItem('token');
        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
    },
    responseHandler: (response) => response.text().then((text) => {
        try {
            return JSON.parse(text);
        } catch {
            return { data: text };
        }
    }),
});

export const adminApi = createApi({
    reducerPath: "adminApi",
    baseQuery: baseQueryWithAuth,
    endpoints: (builder) => ({
        getAllAdmins: builder.query({
            query: ({ pageSize, pageIndex }) => `api/Admin/GetAll?pageSize=${pageSize}&pageIndex=${pageIndex}`,
        }),
        getAdminById: builder.query({
            query: (id) => `api/Admin/GetById?id=${id}`,
        }),
        createAdmin: builder.mutation({
            query: (admin) => {
                const formData = new FormData();
                formData.append('Email', admin.email);
                formData.append('Password', admin.password);
                formData.append('ConfirmPassword', admin.confirmPassword);
                return {
                    url: "/api/Admin/CreateAdmin",
                    method: "POST",
                    body: formData,
                };
            },
        }),
        updateAdmin: builder.mutation({
            query: (admin) => ({
                url: `/api/Admin/UpdateAdminData/${admin.id}`,
                method: "PUT",
                body: admin,
                headers: {
                    "Content-Type": "application/json"
                }
            })
        }),
        deleteAdmin: builder.mutation({
            query: (id) => ({
                url: `api/Admin/DeleteAdmin?id=${id}`,
                method: 'DELETE',
            })
        })
    })
});

export const {
    useGetAdminByIdQuery,
    useGetAllAdminsQuery,
    useCreateAdminMutation,
    useUpdateAdminMutation,
    useDeleteAdminMutation
} = adminApi;