import React, { useState, useEffect } from 'react';
import './OrdersComponent.css';
import { useGetAllOrdersQuery } from '../../redux/OrderApi';
import { format, parseISO } from 'date-fns';

export default function OrdersComponent() {
    const { data, isSuccess, isError, isLoading } = useGetAllOrdersQuery({ pageIndex: 1, pageSize: 32 });
    const [orders, setOrders] = useState([]);

    useEffect(() => {
        const savedOrders = JSON.parse(localStorage.getItem('orders')) || [];

        if (isSuccess && data) {
            const newOrders = data.filter(order => !savedOrders.some(savedOrder => savedOrder.id === order.id));
            setOrders([...savedOrders, ...newOrders]);
        } else {
            setOrders(savedOrders);
        }
    }, [isSuccess, data]);

    useEffect(() => {
        if (orders.length > 0) {
            localStorage.setItem('orders', JSON.stringify(orders));
        }
    }, [orders]);

    const handleMarkAsChecked = (orderId) => {
        setOrders(prevOrders =>
            prevOrders.map(order =>
                order.id === orderId ? { ...order, checked: !order.checked } : order
            ).sort((a, b) => a.checked - b.checked)
        );
    };

    return (
        <div className='ordersComponent'>
            {isLoading && <p>Loading...</p>}
            {isError && <p>Error loading orders.</p>}
            {!isLoading && orders?.length > 0 && (
                <div className="ordersList">
                    {orders?.map(order => (
                        <div key={order.id} className="orderItem">
                            <h3 className={order.checked ? 'checked' : ''}>{order.customerName}</h3>
                            <p>Phone: {order.customerNumber}</p>
                            <p>Model: {order.productModelName}</p>
                            <p>
                                Date: <span>{format(parseISO(order.createDate), 'hh:mm')}</span>
                                {format(parseISO(order.createDate), 'dd.MM.yyyy')}
                            </p>
                            <button
                                className="markAsCheckedButton"
                                onClick={() => handleMarkAsChecked(order.id)}
                            >
                                {order.checked ? 'Unmark as Checked' : 'Mark as Checked'}
                            </button>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}